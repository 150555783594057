import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, array, boolean } from 'yup';

export interface InstitutionFormData {
  id?: number;
  serviceName: string;
  city: string;
  county: string;
  state: string;
  zipCode: string;
  address: string;
  fullName: string;
  email: string;
  countiesServed: string[];
  workPhone: string | null;
  cellPhone: string | null;
  insPOC: IInstitutePOC[] | [];
}

export interface IInstitutePOC {
  id?: number;
  fullName: string;
  email: string;
  workPhone: string | null;
  cellPhone: string | null;
  recruitementPerson: boolean;
}

export const defaultValues: InstitutionFormData = {
  serviceName: '',
  city: '',
  county: '',
  state: '',
  zipCode: '',
  address: '',
  fullName: '',
  email: '',
  countiesServed: [],
  workPhone: '',
  cellPhone: '',
  insPOC: [
    {
      fullName: '',
      email: '',
      workPhone: '',
      cellPhone: '',
      recruitementPerson: false,
    },
    {
      fullName: '',
      email: '',
      workPhone: '',
      cellPhone: '',
      recruitementPerson: false,
    },
  ],
};

function useInstitutionForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        serviceName: string().required('This field is required!'),
        city: string().nullable(),
        county: string().nullable(),
        state: string().nullable(),
        zipCode: string().nullable(),
        address: string().nullable(),
        fullName: string().required('This field is required!'),
        email: string().email('Not a valid email!').required('This field is required!'),
        countiesServed: array().min(1, 'Select at least one county'),
        workPhone: string(),
        cellPhone: string(),
        insPOC: array().of(
          object().shape(
            {
              fullName: string().required('This field is required!'),
              email: string().email('Not a valid email!').required('This field is required!'),
              workPhone: string(),
              cellPhone: string(),
              recruitementPerson: boolean(),
            },
            [['email', 'fullName']],
          ),
        ),
      }),
    [],
  );

  return useForm<InstitutionFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useInstitutionForm;
