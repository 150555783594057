import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Button, FormGroup, Typo } from 'components/primitives';
import useStyles from './DeclineResources.styles';
import { IResponsesDB } from 'interfaces/response.interface';
import { useAppSelector } from 'store/hooks';
import { selectProcessing } from 'store/entities/dashboard/dashboard.selector';

const DeclineResources = ({
  incidentResponse,
  onOk,
  emailMsg,
  changeTextEmailBody,
}: {
  incidentResponse: IResponsesDB | undefined;
  title?: string;
  onOk?: (confirm: boolean, payload: IResponsesDB) => void;
  emailMsg?: string;
  changeTextEmailBody: (value: string) => void;
} & BoxProps) => {
  const classes = useStyles();
  const isProcessing = useAppSelector(selectProcessing);
  const onTouchButton = (ok: boolean) => {
    if (onOk && incidentResponse) onOk(ok, incidentResponse);
  };
  return (
    <Box className={classes.content}>
      <Typo mb={2} variant='h4'>
        Dismiss offered resources
      </Typo>
      <Typo mb={1} variant='body1' color='common.black'>
        Please provide a reason for dismissal.
      </Typo>
      <Typo variant='body1' color='common.black'>
        You can send it or edit if needed
      </Typo>
      <Box mt={3}>
        <FormGroup label='Email body'>
          <textarea
            className={classes.textarea}
            value={emailMsg}
            onChange={(event) => changeTextEmailBody(event.target.value)}
            spellCheck='false'
            rows={10}
          />
        </FormGroup>
      </Box>
      <Box className={classes.footer}>
        <Button onClick={() => onTouchButton(false)} neutral fullWidth disabled={isProcessing}>
          Cancel
        </Button>
        <Button progress={isProcessing} onClick={() => onTouchButton(true)} fullWidth>
          Send dismissal
        </Button>
      </Box>
    </Box>
  );
};

export default DeclineResources;
