// noinspection ExceptionCaughtLocallyJS

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IIncidentDetails } from 'interfaces/IncidentRequests';
import { addIncident, getMaxRequestId, updateIncident } from 'services/incidentEndPoints';
import apiFetch from 'services/apiFetch';
import { RequestFormData } from 'pages/Dashboard/IncidentRequest/hooks/useRequestForm';
import { RootState } from 'store';
import { getAgencyCount } from 'services/agencyEndPoints';
import { IGetAgencyCount } from 'interfaces/incident.interface';
import { v4 as uuidv4 } from 'uuid';

export const createIncident = createAsyncThunk(
  'incident/createIncident',
  async (data: RequestFormData, { getState }) => {
    try {
      const { token } = getState() as RootState;
      const user = token.user;
      const payload: IIncidentDetails = {
        missionType: data.incidentDetails.missionType,
        requestId: data.incidentDetails.requestId,
        date: data.incidentDetails.date,
        departmentName: data.incidentDetails.departmentName,
        contactPerson: data.incidentDetails.contactPerson,
        contactPhone: data.incidentDetails.contactPhone,
        stagingLocation: data.incidentDetails.stagingLocation,
        destination: data.incidentDetails.destination ? data.incidentDetails.destination : '',
        notes: data.incidentDetails.notes,
        deleted: 0,
        archived: 0,
        emailBody: data.emailBody.replace(/\r\n|\r|\n/g, '<br>'),
        ambulance: data.resources.ambulance,
        personnel: data.resources.personnel,
        regions: data.regions,
        userId: user?.id,
        groupId: uuidv4(),
      };
      const endpoint = addIncident(payload);
      const response = await apiFetch<IIncidentDetails>(endpoint).then((res) => res?.data || null);
      if (response && response.id) {
        return 'Success';
      }
      throw Error('Something went wrong!');
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('createIncident catch:', message);
      throw Error(message);
    }
  },
);
export const changeIncident = createAsyncThunk(
  'incident/changeIncident',
  async (data: RequestFormData, { getState }) => {
    try {
      const { token } = getState() as RootState;
      const user = token.user;
      const payload: IIncidentDetails = {
        id: data.id,
        missionType: data.incidentDetails.missionType,
        requestId: data.incidentDetails.requestId,
        date: data.incidentDetails.date,
        departmentName: data.incidentDetails.departmentName,
        contactPerson: data.incidentDetails.contactPerson,
        contactPhone: data.incidentDetails.contactPhone,
        stagingLocation: data.incidentDetails.stagingLocation,
        destination: data.incidentDetails.destination ? data.incidentDetails.destination : '',
        notes: data.incidentDetails.notes,
        deleted: 0,
        archived: 0,
        emailBody: data.emailBody.replace(/\r\n|\r|\n/g, '<br>'),
        ambulance: data.resources.ambulance,
        personnel: data.resources.personnel,
        regions: data.regions,
        userId: user?.id,
        groupId: uuidv4(),
      };
      const endpoint = updateIncident(payload);
      const response = await apiFetch<IIncidentDetails>(endpoint).then((res) => res?.data || null);

      if (response && response.id) {
        return 'Success';
      }
      throw Error('Something went wrong');
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('changeIncident catch:', message);
      throw Error(message);
    }
  },
);

export const fetchAgencyCount = createAsyncThunk('agencies/count', async (county: string) => {
  try {
    const endpoint = getAgencyCount(county);
    const response = await apiFetch<IGetAgencyCount>(endpoint).then((res) => res?.data || null);

    if (response) {
      return response.count;
    } else {
      throw Error('Something went wrong');
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchAgencyCount catch:', message);
    throw Error(message);
  }
});

export const fetchMaxRequestId = createAsyncThunk('incident/maxrequestid', async () => {
  try {
    const endpoint = getMaxRequestId();
    const response = await apiFetch<{ max: number }>(endpoint).then((res) => res?.data || null);
    if (response) {
      return response.max + 1;
    } else {
      throw Error('Something went wrong!');
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchMaxRequestId catch:', message);
    throw Error(message);
  }
});
