import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AgencyFormData,
  defaultValues,
  IPositions,
  JobSorting,
} from 'pages/Dashboard/AgencyDetails/hooks/useAgencyForm';
import {
  addAgency,
  ApiAgencyFilters,
  deleteAgency,
  getAgency,
  getAgencyById,
  getAgencyEdit,
  getAgencyNames,
  getMainAgencies,
  getUpdatedTokenById,
  sendReqUpdateMsg,
  updateAgency,
} from 'services/agencyEndPoints';
import apiFetch from 'services/apiFetch';
import apiFetchMagic from 'services/apiFetchMagic';
import {
  AgencyInterfaces,
  IAgencyDetails,
  IAgencyISelectLists,
  IGetAgencyPayloadState,
} from 'interfaces/agency.interface';
import { RootState } from '../../index';
import { SendReqMsgPayload } from 'interfaces/blastmsg.interface';

export const createAgency = createAsyncThunk(
  'agencies/createAgency',
  async (data: IAgencyDetails) => {
    try {
      const endpoint = addAgency(data);
      return await apiFetch<Partial<IAgencyDetails>>(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('createAgency catch:', message);
      throw Error(message);
    }
  },
);

export const changeAgency = createAsyncThunk(
  'agencies/updateAgency',
  async (payload: { data: IAgencyDetails; accessToken?: string | undefined }) => {
    try {
      const AgencyData: IAgencyDetails = {
        ...payload.data,
        poc: payload.data.poc.filter((p) => p.email?.length && p.fullName?.length) || [],
      };
      const params: Partial<{ data: IAgencyDetails; authorize: boolean; accessToken: string }> = {
        data: AgencyData,
        authorize: !payload?.accessToken,
        accessToken: payload?.accessToken,
      };
      const endpoint = updateAgency(params);
      return await apiFetchMagic<Partial<IAgencyDetails>>(endpoint)
        .then((res) => res?.data || null)
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('updateAgency catch:', message);
      throw Error(message);
    }
  },
);

export const removeAgency = createAsyncThunk(
  'agencies/removeAgency',
  async (data: { id: string; deleted: number }) => {
    try {
      const endpoint = deleteAgency(data);
      return await apiFetch<Partial<IAgencyDetails>>(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('removeAgency catch:', message);
      throw Error(message);
    }
  },
);

export const fetchAgencies = createAsyncThunk(
  'agencies/fetchAgency',
  async (filters: ApiAgencyFilters) => {
    try {
      const endpoint = getAgency(filters);
      const response = await apiFetch<AgencyInterfaces>(endpoint).then((res) => res?.data || null);
      if (response) {
        const pageCount = response.last_page;
        const agenciesData: AgencyFormData[] = response.data.map((info) => ({
          id: info.id,
          general: {
            agencyName: info.agencyName,
            agencyType: info.parentAgency === null ? 'main' : 'substation',
            mainAgency: info.parentAgency,
            city: info.city !== undefined ? info.city : defaultValues.general.city,
            county: info.county,
            state: info.state !== undefined ? info.state : defaultValues.general.state,
            zipCode: info.zipCode !== undefined ? info.zipCode : defaultValues.general.zipCode,
            address: info.address,
          },
          director: {
            fullName: info.fullName,
            cellPhone: info.cellPhone,
            email: info.email,
            workPhone: info.workPhone,
          },
          resources: {
            annualCall: info.annualCall,
            baselineCoverage: info.baselineCoverage,
            peakCoverage: info.peakCoverage,
            permittedAmbulance: info.permittedAmbulance,
            serviceType: info.serviceType,
            shiftRotation:
              info.shiftRotation !== null
                ? info.shiftRotation
                : defaultValues.resources.shiftRotation,
            countiesServed: info.countiesServed,
          },
          poc: info.poc.length === 0 ? defaultValues.poc : info.poc,
          equipment: info.equipment.length === 0 ? defaultValues.equipment : info.equipment,
          personnel: info.personnel.length === 0 ? defaultValues.personnel : info.personnel,
          position:
            info.position.length === 0
              ? defaultValues.position
              : info.position.sort((item1, item2) => (item1.job > item2.job ? 1 : -1)),
          deleted: info.deleted !== undefined ? info.deleted : defaultValues.deleted,
        }));

        const payload: IGetAgencyPayloadState = {
          agencies: agenciesData,
          pageTotal: pageCount,
        };
        return payload;
      } else {
        Error('No data found');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchAgency catch:', message);
      throw Error(message);
    }
  },
);

export const fetchMainAgenciesList = createAsyncThunk(
  'agencies/fetchMainList',
  async (useAuth: boolean, { getState }) => {
    try {
      const state = getState() as RootState;
      const magicToken = state.agencies.accessToken || '';
      const endpoint = getMainAgencies({ authorize: useAuth, accessToken: magicToken });
      const response = await apiFetchMagic<IAgencyISelectLists>(endpoint).then(
        (res) => res?.data || [],
      );
      if (response) {
        return response;
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchMainList catch:', message);
      throw Error(message);
    }
  },
);

export const fetchAgencyNames = createAsyncThunk('agencies/fetchAgencyNames', async () => {
  try {
    const endpoint = getAgencyNames();
    const response = await apiFetch<IAgencyISelectLists>(endpoint).then((res) => res?.data || []);
    if (response) {
      return response;
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchAgencyNames catch:', message);
    throw Error(message);
  }
});

export const fetchAgencyEdit = createAsyncThunk(
  'agencies/fetchAgencyEdit',
  async (accessToken: string) => {
    try {
      const endpoint = getAgencyEdit(accessToken);
      const response = await apiFetchMagic<IAgencyDetails>(endpoint).then(
        (res) => res?.data || null,
      );
      if (response) {
        return CreateAgencyPayloadForm(response);
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchAgencyEdit catch:', message);
      throw Error(message);
    }
  },
);

export const fetchAgencyById = createAsyncThunk('agencies/fetchById', async (id: string) => {
  try {
    const endpoint = getAgencyById(id);
    const resp = await apiFetch<IAgencyDetails>(endpoint).then((res) => res?.data || null);
    if (resp) {
      return CreateAgencyPayloadForm(resp);
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchAgencyById catch:', message);
    throw Error(message);
  }
});

export const fetchUpdatedTokenById = createAsyncThunk(
  'agencies/fetchUpdatedTokenById',
  async (id: string) => {
    try {
      const endpoint = getUpdatedTokenById(id);
      return await apiFetch<string>(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchUpdatedTokenById catch:', message);
      throw Error(message);
    }
  },
);

export const sendMsg = createAsyncThunk('agencies/sendMsg', async (payload: SendReqMsgPayload) => {
  try {
    const endpoint = sendReqUpdateMsg(payload);
    return await apiFetch<{ message: string }>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('sendMsg catch:', message);
    throw Error(message);
  }
});

function CreateAgencyPayloadForm(info: IAgencyDetails) {
  const payload: AgencyFormData = {
    id: info.id,
    general: {
      agencyName: info.agencyName,
      agencyType: info.parentAgency === null ? 'main' : 'substation',
      mainAgency: info.parentAgency,
      city: info.city !== undefined ? info.city : defaultValues.general.city,
      county: info.county,
      state: info.state !== undefined ? info.state : defaultValues.general.state,
      zipCode: info.zipCode !== undefined ? info.zipCode : defaultValues.general.zipCode,
      address: info.address,
    },
    director: {
      fullName: info.fullName,
      email: info.email,
      cellPhone: info.cellPhone,
      workPhone: info.workPhone,
    },
    resources: {
      annualCall: info.annualCall,
      baselineCoverage: info.baselineCoverage,
      peakCoverage: info.peakCoverage,
      permittedAmbulance: info.permittedAmbulance,
      serviceType: info.serviceType,
      shiftRotation: info.shiftRotation,
      countiesServed: info.countiesServed,
    },
    poc: info.poc && info.poc.length === 0 ? defaultValues.poc : info.poc,
    equipment:
      info.equipment && info.equipment.length === 0 ? defaultValues.equipment : info.equipment,
    personnel:
      info.personnel && info.personnel.length === 0 ? defaultValues.personnel : info.personnel,
    position:
      info?.position && info?.position.length === 0
        ? defaultValues.position
        : mapOrder(info.position),
    deleted: info.deleted !== undefined ? info.deleted : defaultValues.deleted,
  };
  return payload;
}

function mapOrder(array: IPositions[]): IPositions[] {
  const sortedData: IPositions[] = [];

  for (let i = 0; i < JobSorting.length; i++) {
    const el = JobSorting[i];
    array.find((p) => {
      if (p.job === el.job && p.type === el.type) {
        sortedData.push(p);
      }
    });
  }
  return sortedData;
}
