import { RootState } from 'store';

export const selectIncidentsDB = (state: RootState) => state.dashboard.incidents;
export const selectResponsesDB = (state: RootState) => state.dashboard.responses;
export const selectPageDB = (state: RootState) => state.dashboard.page;
export const selectLastPageDB = (state: RootState) => state.dashboard.lastPage;
export const selectPageLimitDB = (state: RootState) => state.dashboard.pageLimit;
export const selectPageTotalDB = (state: RootState) => state.dashboard.pagesTotal;
export const selectLoadingDB = (state: RootState) => state.dashboard.loading;
export const selectStateStatus = (state: RootState) => state.dashboard.state;
export const selectProcessing = (state: RootState) => state.dashboard.processing;
