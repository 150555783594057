import React from 'react';
import { Box, Stack, Grid } from '@mui/material';
import { Typo } from 'components/primitives';
import HeaderTitleAmbulance from './HeaderTitleAmbulance';
import { ResourceType } from 'interfaces/ResourceType';
import useStyles from './ProvidedResources.styles';
import { IIncidentAmbulance } from 'interfaces/IncidentRequests';
import { ISelectProp } from 'interfaces/SelectProp';
import { AmbulanceOptions } from 'common/selectoptions';

const selectLabel = (
  value: string | undefined,
  SelectOptions: ISelectProp[],
): string | undefined => {
  if (value) {
    const findValue = SelectOptions.find((m: ISelectProp) => m.value === value);
    return findValue?.label;
  } else {
    return undefined;
  }
};

const AmbulanceHeader: React.FC<{
  name: ResourceType;
  index: number;
  ambulance: IIncidentAmbulance;
}> = ({ index, ambulance }) => {
  const classes = useStyles();
  const ttlRequested = ambulance.number || 0;
  const accepted =
    (ambulance && ambulance?.items && ambulance.items.filter((a) => a.accepted)) || [];
  const ttlAccepted = accepted.length || 0;

  return (
    <React.Fragment key={index}>
      <Box className={classes.contentStackView}>
        {/*  Headers  */}
        <Stack direction='row' pb={1} rowGap={1} className={classes.headerGroup}>
          <Typo variant={'h6'} fontWeight={700}>
            {selectLabel(ambulance.vehicleDescription, AmbulanceOptions)}
          </Typo>
          <Box className={classes.rightColGroup}>
            <Box className={classes.wrapCenter}>
              <Typo variant='h6' className={classes.title}>
                Requested
              </Typo>
              <Typo variant='h5' className={classes.requestCount}>
                {ttlRequested}
              </Typo>
            </Box>
            <Box className={classes.wrapCenter}>
              <Typo variant='h6' className={classes.title}>
                Accepted:
              </Typo>
              <Typo variant='h5' className={classes.acceptCount}>
                {ttlAccepted}
              </Typo>
            </Box>
          </Box>
        </Stack>
        {/* Title Column Headers */}
        <HeaderTitleAmbulance />
        {ambulance &&
          ambulance.items &&
          accepted.map((a, idx: number) => {
            return (
              <React.Fragment key={a.id || idx}>
                <Grid container spacing={1} py={1}>
                  <Grid item xs={10}>
                    <Typo variant={'body1'} color={'black'}>
                      {a.vehicle}
                    </Typo>
                  </Grid>
                  <Grid item xs={2}>
                    <Typo variant={'body1'} color={'black'} fontWeight={700}>
                      {a.details}
                    </Typo>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
      </Box>
    </React.Fragment>
  );
};

export default AmbulanceHeader;
