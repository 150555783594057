/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Control, Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import { Checkbox, Stack, useTheme, useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { ResourceType } from 'interfaces/ResourceType';

import useStyles from './ResourceTabPanel.styles';
import {
  IResponseAmbulance,
  IResponseAmbulanceDetail,
  IResponsePersonnel,
  IResponsePersonnelDetail,
} from 'pages/EMSResponse/hooks/useResponseForm';
import { ISelectProp } from 'interfaces/SelectProp';
import { AmbulanceOptions, JobPositions } from 'common/selectoptions';

const selectLabel = (
  value: string | undefined,
  SelectOptions: ISelectProp[],
): string | undefined => {
  if (value) {
    const findValue = SelectOptions.find((m: ISelectProp) => m.value === value);
    return findValue?.label;
  } else {
    return undefined;
  }
};

const HiddenBox: React.FC<{
  children: React.ReactNode;
  visible?: boolean;
}> = ({ children, visible = false }) => <React.Fragment>{visible && children}</React.Fragment>;

const ItemCheckbox: React.FC<{
  control: Control;
  name: string;
  handleClick: () => void;
}> = ({ control, name }) => {
  const fieldName = `incidents.${name}`;
  return (
    <Controller
      {...{ control, name: fieldName }}
      render={({ field: { onChange, value } }) => <Checkbox checked={value} onChange={onChange} />}
    />
  );
};

const ResourceItems: React.FC<
  { index: number; name: ResourceType; handleClick: () => void } & BoxProps
> = ({ index, name, handleClick, ...props }) => {
  const { control } = useFormContext();
  const itemsField = useFieldArray({ name: `incidents.${name}.${index}.items`, control });

  return (
    <React.Fragment>
      {itemsField.fields.map(
        (item: IResponseAmbulanceDetail | IResponsePersonnelDetail | any, i: number) => (
          <Stack key={item?.id || i} direction='row' className={props.className}>
            <HiddenBox visible={name === 'personnel'}>
              <Box>
                <ItemCheckbox
                  name={`${name}.${index}.items.${i}.accepted`}
                  control={control}
                  handleClick={handleClick}
                />
                {item.name}
              </Box>
              <Box>{item.idNumber}</Box>
              <Box>{item.rateOfPay}</Box>
            </HiddenBox>
            <HiddenBox visible={name === 'ambulance'}>
              <Box>
                <ItemCheckbox
                  name={`${name}.${index}.items.${i}.accepted`}
                  control={control}
                  handleClick={handleClick}
                />
                {item.vehicle}
              </Box>
              <Box>{item.details}</Box>
            </HiddenBox>
          </Stack>
        ),
      )}
    </React.Fragment>
  );
};

const ResourceTabPanel: React.FC<
  { active?: boolean; name: ResourceType; handleClick: () => void } & BoxProps
> = ({ active = false, name, handleClick, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setValue } = useFormContext();
  const fieldName = name === 'ambulance' ? 'incidents.ambulance' : 'incidents.personnel';
  const resourceField = useFieldArray({
    name: fieldName,
  });
  const resourceWatch =
    useWatch({
      name: fieldName,
    }) || [];

  const onSelectAll =
    (name: string, unchecked: boolean, resource: IResponseAmbulance | IResponsePersonnel) => () => {
      const updatedItems =
        resource?.items &&
        resource?.items.map((item) => ({
          ...item,
          accepted: !unchecked,
        }));

      setValue(name, {
        ...resource,
        items: updatedItems,
      });
    };

  return (
    <Box
      id={`resource-tab-panel-${name}`}
      aria-labelledby={`resource-tab-${name}`}
      hidden={!active}
      role='tab-panel'
      {...props}>
      {active ? (
        <Stack className={classes.stackView} pb={1} rowGap={1}>
          {resourceField.fields.map(
            (r: IResponseAmbulance | IResponsePersonnel | any, index: number) => {
              let checked = 0;
              if (Array.isArray(resourceWatch)) {
                if (resourceWatch.length) {
                  if (resourceWatch[index].items) {
                    checked = resourceWatch[index].items.filter(
                      (item: IResponseAmbulanceDetail | IResponsePersonnelDetail) => item.accepted,
                    ).length;
                  }
                }
              }

              return (
                <React.Fragment key={r.id}>
                  <Stack
                    className={clsx(classes.personnelItem, classes.sectionItem)}
                    direction='row'>
                    <Box>
                      <Checkbox
                        name={`incidents.${name}.${index}`}
                        checked={checked > 0}
                        sx={{
                          opacity: checked > 0 && checked < r.items.length ? 0.5 : 1,
                        }}
                        onChange={onSelectAll(
                          `incidents.${name}.${index}`,
                          r.items.length === checked,
                          r,
                        )}
                      />
                      {name === 'personnel'
                        ? selectLabel(r.type, JobPositions)
                        : selectLabel(r.vehicleDescription, AmbulanceOptions)}
                    </Box>
                    <HiddenBox visible={name === 'personnel'}>
                      <Box>{isMobile ? 'ID' : 'ID Number'}</Box>
                      <Box>{isMobile ? 'Rate' : 'Rate of pay'}</Box>
                    </HiddenBox>
                    <HiddenBox visible={name === 'ambulance'}>
                      <Box>Code</Box>
                    </HiddenBox>
                  </Stack>
                  <ResourceItems
                    className={classes.personnelItem}
                    {...{ index, name, handleClick }}
                  />
                </React.Fragment>
              );
            },
          )}
        </Stack>
      ) : null}
    </Box>
  );
};

export default ResourceTabPanel;
