import DefaultPaletteOptions from 'theming/defaultPalette';
// eslint-disable-next-line
export default (palette: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {},
      rounded: {
        borderRadius: '8px',
      },
      elevation3: {
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
      },
    },
  };
};
