import { createSlice } from '@reduxjs/toolkit';
import { ConsultationEmptyState, IConsultationState } from './consultationState';
import { createConsultation, fetchConsultation } from './consultationThunk';

const initialState: IConsultationState = ConsultationEmptyState;

export const consultationSlice = createSlice({
  name: 'consultation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createConsultation.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.consultation = null;
      })
      .addCase(createConsultation.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createConsultation.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(fetchConsultation.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.consultation = null;
      })
      .addCase(fetchConsultation.fulfilled, (state, action) => {
        state.loading = false;
        state.consultation = action.payload;
      })
      .addCase(fetchConsultation.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default consultationSlice.reducer;
