import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersEmptyState, IUserState } from './UsersSate';
import { IUserInfo } from 'pages/Dashboard/UserManage/hooks/useUserForm';
import {
  fetchUsersList,
  fetchEMSList,
  updateSelectedUser,
  deleteUser,
  createUser,
} from './usersActions';
import { QueryState } from 'common/enums';

const initialState: IUserState = UsersEmptyState;

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: (state) => {
      state.users = [];
    },
    updateUser: (state, action: PayloadAction<IUserInfo>) => {
      state.selectUser = action.payload;
    },
    updatePage: (state, action: PayloadAction<{ PageNum: number }>) => {
      state.page = action.payload.PageNum;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsersList.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.users = action.payload.users || [];
        state.pageTotal = action.payload.pageTotal || 0;
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.users = [];
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(updateSelectedUser.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(updateSelectedUser.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
        state.selectUser = action.payload?.data;
      })
      .addCase(updateSelectedUser.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(deleteUser.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.state = QueryState.UPDATED;
        state.selectUser = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(createUser.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(fetchEMSList.fulfilled, (state, actions) => {
        state.emsSelectOptions = actions.payload?.data || [];
      });
  },
});

export const { updateUser, resetUsers, updatePage } = usersSlice.actions;

export default usersSlice.reducer;
