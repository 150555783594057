import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    container: {
      width: '100%',
      paddingBottom: theme.spacing(2),
    },
  };
});

export default useStyles;
