import { QueryState, RequestFormStep } from 'common/enums';
import { Nullable } from 'utils/Nullable';
import { StackableStep } from 'components/fragments/StackableSteps';
import {
  defaultRequestFormData,
  RequestFormData,
} from 'pages/Dashboard/IncidentRequest/hooks/useRequestForm';

export interface IIncidentsState {
  state: QueryState;
  loading: boolean;
  success: boolean;
  steps: StackableStep[];
  newRequestId: number;
  agencyCount: number;
  error: Nullable<string> | undefined;
  incidentForm: RequestFormData;
}

export const IncidentsEmptyState: IIncidentsState = {
  state: QueryState.UNDEFINED,
  loading: false,
  success: false,
  error: null,
  newRequestId: 0,
  agencyCount: 0,
  incidentForm: defaultRequestFormData,
  steps: [
    {
      step: RequestFormStep.INCIDENT_DETAILS,
      title: 'Incident Details',
      active: true,
    },
    {
      step: RequestFormStep.AMBULANCE,
      title: 'Add ambulance',
      active: false,
    },
    {
      step: RequestFormStep.PERSONNEL,
      title: 'Add personnel',
      active: false,
    },
    {
      step: RequestFormStep.REGIONS,
      title: 'Additional details',
      active: false,
    },
    {
      step: RequestFormStep.SEND_REQUEST,
      title: 'Send request',
      active: false,
    },
  ],
};
