import React from 'react';
import { Controller, Control, FieldValues } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import { Paper, useTheme } from '@mui/material';
import { ISelectProp } from 'interfaces/SelectProp';
import useStyles from './MultiSelectCard.styles';
import { Typo, FormGroup, MultiSelect } from 'components/primitives';

const MultiSelectCardView: React.FC<
  {
    name: string;
    control: Control<FieldValues, any>; // eslint-disable-line
    title: string;
    label: string;
    optionsValue: ISelectProp[];
  } & BoxProps
> = ({ name, control, title, label, optionsValue, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();

  const selectLabel = (value: string[]) => {
    return optionsValue.filter((option) => value.includes(option.value || ''));
  };

  const handleChange =
    (onChange: (value: string | string[] | undefined | null) => void) =>
    (newValue: ISelectProp[]) => {
      const allServices = optionsValue.map((item) => item['value']).slice(1);

      const tempValue = newValue.map((item) => item['value']);
      const isAll = tempValue.findIndex((val) => val === 'all');

      if (isAll > -1) {
        onChange(allServices);
      } else {
        onChange(tempValue);
      }
    };

  return (
    <Box {...props}>
      <Box className={classes.root}>
        <Paper elevation={0} variant='outlined' className={classes.paperWrapper}>
          <Typo variant='h6' sx={{ mb: theme.spacing(1) }}>
            {title}
          </Typo>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label={label} error={error}>
                <MultiSelect
                  white={true}
                  error={Boolean(error)}
                  options={optionsValue}
                  onChange={handleChange(onChange)}
                  value={selectLabel(value)}
                />
              </FormGroup>
            )}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default MultiSelectCardView;
