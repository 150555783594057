import React from 'react';
import { withStyles } from '@mui/styles';
import MuiTextField, { TextFieldProps as MuiTextProps } from '@mui/material/TextField';
import { TextStyles } from './TextField.styles';

interface TextFieldCustomProps {
  white?: boolean;
}

const WhiteTextField = withStyles(TextStyles)(MuiTextField);

const TextField: React.FC<TextFieldCustomProps & MuiTextProps> = ({ white = false, ...props }) => {
  if (white)
    return (
      <WhiteTextField
        variant='outlined'
        autoComplete='new-password'
        fullWidth
        inputProps={{
          autoComplete: 'new-password',
        }}
        {...props}
      />
    );
  return (
    <MuiTextField
      variant='outlined'
      autoComplete='new-password'
      fullWidth
      inputProps={{
        autoComplete: 'new-password',
      }}
      {...props}
    />
  );
};

export default TextField;
