import React from 'react';
import { Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { ResourceType } from 'interfaces/ResourceType';
import AmbulanceHeader from './AmbulanceHeader';
import { IIncidentAmbulance } from 'interfaces/IncidentRequests';

const ProvidedResourceAmbulance: React.FC<
  {
    name: ResourceType;
    active: boolean;
    ambulance: IIncidentAmbulance[];
  } & BoxProps
> = ({ active = false, name, ambulance }) => {
  return (
    <Stack
      id={`provided-tab-panel-${name}`}
      aria-labelledby={`provided-tab-${name}`}
      hidden={!active}
      role='tab-panel'>
      <Box>
        {active && ambulance && ambulance.length ? (
          ambulance.map((a, idx: number) => (
            <AmbulanceHeader key={idx} name={name} index={idx} ambulance={a} />
          ))
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  );
};

export default ProvidedResourceAmbulance;
