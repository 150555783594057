import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EducationEmptyState, IEducationState } from 'store/entities/institution/InstitutionState';
import {
  fetchInstitutions,
  fetchInstituteById,
  updateRecordInstitution,
  deleteInstitution,
  addInstitution,
} from './institutionThunk';
import { QueryState } from 'common/enums';
import { InstitutionFormData } from 'pages/Dashboard/InstitutionDetails/hooks/useInstitutionForm';

const initialState: IEducationState = EducationEmptyState;

export const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {
    updatePage: (state, action: PayloadAction<{ PageNum: number }>) => {
      state.page = action.payload.PageNum;
    },
    updateInstitution: (state, action: PayloadAction<InstitutionFormData>) => {
      state.institution = action.payload;
    },
    resetState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInstitutions.pending, (state) => {
        state.loading = true;
        state.state = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchInstitutions.fulfilled, (state, action) => {
        state.loading = false;
        state.state = QueryState.READY;
        state.institutionList = action.payload?.institutionList || [];
        state.pageTotal = action.payload?.pageTotal || 0;
        state.lastPage = action.payload?.lastPage || 0;
      })
      .addCase(fetchInstitutions.rejected, (state, action) => {
        state.loading = false;
        state.state = QueryState.FAIL;
        state.error = action.error.message;
      })
      .addCase(fetchInstituteById.pending, (state) => {
        state.loading = true;
        state.institution = null;
        state.institutionList = [];
        state.page = initialState.page;
        state.pageTotal = initialState.pageTotal;
        state.lastPage = initialState.lastPage;
      })
      .addCase(fetchInstituteById.fulfilled, (state, action) => {
        state.loading = false;
        state.institution = action.payload;
      })
      .addCase(fetchInstituteById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateRecordInstitution.pending, (state) => {
        state.state = QueryState.AWAIT;
        state.loading = true;
        state.success = false;
      })
      .addCase(updateRecordInstitution.fulfilled, (state, action) => {
        state.state = QueryState.UPDATED;
        state.loading = false;
        state.success = true;
        state.institution = action.payload;
      })
      .addCase(updateRecordInstitution.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(deleteInstitution.pending, (state) => {
        state.state = QueryState.DELETED;
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(deleteInstitution.fulfilled, (state) => {
        state.state = QueryState.READY;
        state.loading = false;
        state.success = true;
      })
      .addCase(deleteInstitution.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(addInstitution.pending, (state) => {
        state.state = QueryState.AWAIT;
        state.loading = true;
        state.success = false;
      })
      .addCase(addInstitution.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.state = QueryState.CREATED;
      })
      .addCase(addInstitution.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.state = QueryState.FAIL;
        state.error = action.error.message;
      });
  },
});

export const { updatePage, updateInstitution, resetState } = institutionSlice.actions;

export default institutionSlice.reducer;
