import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
import { IIncidentDetails } from 'interfaces/IncidentRequests';
import { ICreateResponse } from 'interfaces/response.interface';

export interface ApiIncidentsFilters {
  limit?: number;
  offset?: number;
  archived?: number;
}

function setIncidentsParams(filters: ApiIncidentsFilters): ApiIncidentsFilters {
  const params: ApiIncidentsFilters = {};
  if (filters.offset || filters.offset === 0) {
    params.offset = filters.offset;
  }

  if (filters.limit) {
    params.limit = filters.limit;
  }

  if (filters.archived) {
    params.archived = filters.archived;
  }

  return params;
}

export const addIncident = (data: IIncidentDetails): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'incident',
    data,
  };
};

export const updateIncident = (data: IIncidentDetails): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'incident',
    data,
  };
};

// Fetch APIs
export const getIncidents = (filters: ApiIncidentsFilters): IEndpoint => {
  const params = setIncidentsParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'incident',
    params: params,
  };
};
export const getIncidentMagic = (accessToken: string): IEndpoint => {
  const params = { access_token: accessToken }; // eslint-disable-line
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: 'response/magiclink',
    params,
  };
};

export const addResponse: (
  payload: ICreateResponse,
  params: Partial<{ authorize: boolean; accessToken: string }>,
) => IEndpoint = (payload, params) => {
  return {
    authorization: params?.authorize ? AuthProtect.REQUIRED : AuthProtect.NOT_REQUIRED,
    url: params?.authorize ? 'response' : `response?access_token=${params?.accessToken}`,
    method: 'POST',
    data: payload,
  };
};

export const changeResponse: (
  payload: ICreateResponse,
  params: Partial<{ authorize: boolean; accessToken: string }>,
) => IEndpoint = (payload, params) => {
  return {
    authorization: params?.authorize ? AuthProtect.REQUIRED : AuthProtect.NOT_REQUIRED,
    url: params?.authorize ? 'response' : `response?access_token=${params?.accessToken}`,
    method: 'PUT',
    data: payload,
  };
};

export const archiveIncident = (data: { id: string }): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'incident/archive',
    data,
  };
};

export const getMaxRequestId = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'incident/requestId',
    method: 'GET',
  };
};

export const getOpenResponses = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'responses',
    method: 'GET',
  };
};

export const getIncidentById = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: `incident/${id}`,
    method: 'GET',
  };
};

export const getResponseByIncident = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    url: 'responses/incident',
    method: 'GET',
    params: { incidentId: id },
  };
};
