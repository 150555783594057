import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
import { PublicFormData } from 'pages/PublicPage/hooks/usePublicForm';

export const addConsultation = (data: PublicFormData): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: 'consultation',
    data,
  };
};

export const getConsultation = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `consultation/${id}`,
  };
};
