import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CustomIcon(props: SvgIconProps) {
  return (
    <div style={{ position: 'relative' }}>
      <SvgIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} {...props}>
        <path d='M3 4.59984L1.58 3.1865L2.52 2.2465L3.93333 3.6665L3 4.59984ZM7.66667 0.666504V2.6665H6.33333V0.666504H7.66667ZM12.42 3.1865L11 4.59984L10.0667 3.6665L11.48 2.2465L12.42 3.1865ZM2 6.99984V8.33317H0V6.99984H2ZM12 6.99984H14V8.33317H12V6.99984ZM3 13.3332H11C11.3536 13.3332 11.6928 13.4736 11.9428 13.7237C12.1929 13.9737 12.3333 14.3129 12.3333 14.6665H1.66667C1.66667 14.3129 1.80714 13.9737 2.05719 13.7237C2.30724 13.4736 2.64638 13.3332 3 13.3332ZM7 3.33317C8.06087 3.33317 9.07828 3.7546 9.82843 4.50474C10.5786 5.25489 11 6.27231 11 7.33317V12.6665H3V7.33317C3 6.27231 3.42143 5.25489 4.17157 4.50474C4.92172 3.7546 5.93913 3.33317 7 3.33317Z' />
      </SvgIcon>
    </div>
  );
}

export default CustomIcon;
