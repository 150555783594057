import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchAgencyEdit } from 'store/entities/agencies/agenciesThunk';
import { updateMagicToken } from 'store/entities/agencies/agenciesSlice';
import { updateUserEmail } from 'store/entities/userAuth/tokenSlice';
import { updateTokenPage } from 'store/entities/pages/ResponsePage/responsePageSlice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectToken } from 'store/selectors';

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = useAppSelector(selectToken);
  const accessToken = searchParams.get('access_token');
  const id = searchParams.get('id');
  const email = searchParams.get('email');

  useEffect(() => {
    const urlPath = location.pathname;
    if (urlPath === '/agency_edit' && accessToken) {
      dispatch(updateMagicToken({ token: accessToken }));
      dispatch(fetchAgencyEdit(accessToken));
      return navigate(`/agency_edit?access_token=${accessToken}`);
    }
    if (urlPath === '/response' && accessToken) {
      dispatch(updateTokenPage({ accessToken }));
      return navigate(`/response?access_token=${accessToken}`);
    }
    if (urlPath === '/consultation') {
      return navigate('/consultation'); // Public Form Page
    }
    if (urlPath === '/consultation/view' && id) {
      return navigate(`/consultation/view?id=${id}`); // Public Form Page
    }
    if (urlPath === '/reset-password') {
      return navigate('/reset-password');
    }
    if (urlPath === '/create-password' && accessToken && email) {
      dispatch(updateUserEmail({ email }));
      return navigate(`/create-password?access_token=${accessToken}`);
    }
    if (token && token.authorization) {
      if (urlPath === '/') return navigate('/dashboard');
      if (urlPath === '/login') return navigate('/dashboard');
      return navigate(urlPath);
    }
    navigate('/login');
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Outlet />;
};

export default AuthPage;
