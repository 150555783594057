import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: '#e5e5e5',
    },
    page: {
      paddingBottom: theme.spacing(3),
      width: '100%',
      height: 'auto',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    avatar: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: '16px',
    },
    toolbar: {
      display: 'flex',
      columnGap: theme.spacing(2),
      minHeight: 'unset',
      height: '100%',
    },
    footer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      left: 0,
      bottom: 0,
      padding: `0 ${theme.spacing(3)}`,
      width: '100%',
      height: '58px',
      backgroundColor: theme.palette.common.white,
    },
  }),
);

const styles = makeStyles((theme: Theme) => ({
  appBarButton: {
    backgroundColor: theme.palette.common.white,
    color: `${theme.palette.text.primary}`,
  },
}));

export { styles };
export default useStyles;
