import { QueryState } from 'common/enums';
import { ISelectProp } from 'interfaces/SelectProp';
import { IUserInfo } from 'pages/Dashboard/UserManage/hooks/useUserForm';
import { Nullable } from 'utils/Nullable';

export interface IUserState {
  state: QueryState;
  users: IUserInfo[];
  selectUser: Nullable<IUserInfo> | undefined;
  emsSelectOptions: ISelectProp[] | [];
  page: number;
  pageLimit: number;
  initialPageTotal: number;
  pageTotal: number;
  error: Nullable<string>;
}

export const UsersEmptyState: IUserState = {
  state: QueryState.UNDEFINED,
  users: [],
  selectUser: null,
  emsSelectOptions: [],
  page: 1,
  pageLimit: parseInt(process.env.REACT_APP_LIST_LIMIT || '10'),
  initialPageTotal: 0,
  pageTotal: 0,
  error: null,
};

export default UsersEmptyState;
