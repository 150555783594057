// noinspection ExceptionCaughtLocallyJS,JSUnusedAssignment

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITokenState } from './TokenState';
import { authLogin, reqResetPass, updatePassword } from 'services/authEndPoints';
import apiFetch from 'services/apiFetch';
import { ILoginResponse } from 'interfaces/auth.interface';
import { RootState } from 'store/index';

export const userLogin = createAsyncThunk(
  'token/userLogin',
  async (params: { username: string; password: string }) => {
    try {
      const endpoint = authLogin(params.username, params.password);

      const resp = await apiFetch<ILoginResponse>(endpoint).then((res) => res?.data || null);

      if (resp && resp.data) {
        const payload: ITokenState = {
          authorization: resp.data.id,
          ttl: resp.data.ttl,
          createdAt: resp.data.createdAt,
          scope: resp.data.scope,
          user: resp.data.user,
          email: resp.data.user.email,
        };
        return payload;
      } else {
        throw Error('Internal Server Error');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('userLogin catch:', error);
      throw Error(message);
    }
  },
);

export const reqReset = createAsyncThunk('token/reqReset', async (email: string) => {
  try {
    const endpoint = reqResetPass(email);
    const resp = await apiFetch<{ data: string }>(endpoint).then((res) => res?.data?.data || null);
    if (resp) {
      return resp;
    }
    throw Error('Internal Server Error');
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('reqReset catch:', message);
    throw Error(message);
  }
});

export const changePassword = createAsyncThunk(
  'token/changePassword',
  async (params: { password: string; accessToken: string }, { getState }) => {
    try {
      const { password, accessToken } = params;
      const state = getState() as RootState;
      const email = state.token.email;
      if (email !== undefined) {
        const endpoint = updatePassword(email, password, accessToken);
        const resp = await apiFetch<ILoginResponse>(endpoint).then((res) => res?.data || null);
        if (resp && resp.data) {
          const payload: ITokenState = {
            authorization: resp.data.id,
            ttl: resp.data.ttl,
            createdAt: resp.data.createdAt,
            scope: resp.data.scope,
            user: resp.data.user,
            email: resp.data.user.email,
          };
          return payload;
        } else {
          throw Error('Internal Server Error');
        }
      }
      throw 'Missing Email';
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('change password catch:', message);
      throw Error(message);
    }
  },
);
