import { QueryState } from 'common/enums';
import { Nullable } from 'utils/Nullable';
import { IncidentData } from 'interfaces/incident.interface';

export interface IArchivedState {
  state: QueryState;
  archiveList: Nullable<IncidentData[]>;
  page: number;
  lastPage: number;
  pageLimit: number;
  initialPageTotal: number;
  pageTotal: number;
  error: Nullable<string>;
}

export const ArchivedEmptyState: IArchivedState = {
  state: QueryState.UNDEFINED,
  archiveList: [],
  page: 1,
  lastPage: 0,
  pageLimit: parseInt(process.env.REACT_APP_LIST_LIMIT || '10'),
  initialPageTotal: 0,
  pageTotal: 0,
  error: null,
};
