import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IncidentsEmptyState, IIncidentsState } from './IncidentsState';
import {
  createIncident,
  fetchAgencyCount,
  fetchMaxRequestId,
  changeIncident,
} from './incidentsThunk';
import { QueryState } from 'common/enums';
import { RequestFormData } from 'pages/Dashboard/IncidentRequest/hooks/useRequestForm';

const initialState: IIncidentsState = IncidentsEmptyState;

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    updateIncidentForm: (state, action: PayloadAction<{ data: RequestFormData }>) => {
      state.incidentForm = action.payload.data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createIncident.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.state = QueryState.AWAIT;
      })
      .addCase(createIncident.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.state = QueryState.CREATED;
      })
      .addCase(createIncident.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(fetchAgencyCount.fulfilled, (state, action) => {
        state.agencyCount = action.payload || state.agencyCount;
      })
      .addCase(fetchMaxRequestId.fulfilled, (state, action) => {
        state.newRequestId = action.payload;
      })
      .addCase(changeIncident.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.state = QueryState.AWAIT;
      })
      .addCase(changeIncident.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.state = QueryState.UPDATED;
      })
      .addCase(changeIncident.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
        state.state = QueryState.FAIL;
      });
  },
});

export const { resetState, updateIncidentForm } = incidentsSlice.actions;

export default incidentsSlice.reducer;
