import React from 'react';
import clsx from 'clsx';
import Box, { BoxProps } from '@mui/material/Box';

import useStyles from './TagTitle.sytles';
import { TagTitleOption } from 'common/enums';

const TagTitleView: React.FC<{ status: TagTitleOption } & BoxProps> = ({ status, ...props }) => {
  const classes = useStyles();
  const isMain = status === TagTitleOption.MAIN;

  return (
    <Box {...props} className={clsx(classes.root, isMain ? classes.main : classes.secondary)}>
      {props.children}
    </Box>
  );
};

export default TagTitleView;
