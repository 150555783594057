import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  archiveIncident,
  getIncidentById,
  getResponseByIncident,
} from 'services/incidentEndPoints';
import {
  getNotificationByIncident,
  sendRemindAll,
  sendRemindByAgency,
} from 'services/notificationEndPoints';
import { IncidentData } from 'interfaces/incident.interface';
import { IResponsesDB } from 'interfaces/response.interface';
import apiFetch from 'services/apiFetch';
import { INotifications } from 'interfaces/notification.interface';

export const fetchIncidentById = createAsyncThunk(
  'incidentDetail/fetchById',
  async (id: string) => {
    try {
      const endpoint = getIncidentById(id);
      const response = await apiFetch<IncidentData>(endpoint).then((res) => res?.data || null);
      if (response) return response;
      else Error('Something went wrong!');
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchIncidentById catch:', message);
      throw Error(message);
    }
  },
);

export const fetchResponsesByIncident = createAsyncThunk(
  'incidentDetail/fetchResponses',
  async (id: string) => {
    try {
      const endpoint = getResponseByIncident(id);
      const response = await apiFetch<IResponsesDB[]>(endpoint).then((res) => res?.data || null);
      if (response) return response;
      else Error('Something went wrong!');
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchResponses catch:', message);
      throw Error(message);
    }
  },
);

export const fetchRequests = createAsyncThunk('incidentDetail/requests', async (id: string) => {
  try {
    const endpoint = getNotificationByIncident(id);
    return await apiFetch<INotifications[]>(endpoint).then((res) => res.data || []);
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchRequests catch:', message);
    throw Error(message);
  }
});

export const execArchiveIncident = createAsyncThunk(
  'incidentDetail/archive',
  async (data: { id: string }) => {
    try {
      const endpoint = archiveIncident(data);
      return await apiFetch<IncidentData>(endpoint).then((res) => res?.data || null);
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('archiveIncident catch:', message);
      throw Error(message);
    }
  },
);

export const remindAgency = createAsyncThunk(
  'incidentDetail/remind',
  async (payload: { incidentId: string; agencyId: string }) => {
    try {
      const { incidentId, agencyId } = payload;
      const endpoint = sendRemindByAgency(incidentId, agencyId);
      const response = await apiFetch<string>(endpoint).then((res) => res?.data || null);
      if (response && response === 'Success') return response;
      else return Error('Notification failed');
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('remindAgency catch:', message);
      throw Error(message);
    }
  },
);

export const remindAll = createAsyncThunk(
  'incidentDetail/remindAll',
  async (incidentId: string) => {
    try {
      const endpoint = sendRemindAll(incidentId);
      const response = await apiFetch<string>(endpoint).then((res) => res?.data || null);
      if (response && response === 'Success') return response;
      else return Error('Notification failed');
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('remindAgency catch:', message);
      throw Error(message);
    }
  },
);
