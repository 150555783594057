import React, { useState, useEffect } from 'react';
import useResourceForm from './hooks/useResourceForm';
import { AcceptResourcesProps, AcceptResourcesPublicProps } from './AcceptResources.props';
import AcceptResourcesView from './AcceptResources.view';
import { IResponsesDB } from 'interfaces/response.interface';

const AcceptResourcesContainer: React.FC<AcceptResourcesPublicProps> = (
  publicProps: AcceptResourcesPublicProps,
) => {
  const { incidentResponse } = publicProps;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const form = useResourceForm();

  useEffect(() => {
    if (incidentResponse) {
      form.reset(incidentResponse);
    }
  }, [form, incidentResponse]);

  const handleAccept = (accept: boolean, payload: IResponsesDB) => () => {
    if (publicProps.onAccept) publicProps.onAccept(accept, payload);
  };

  const combinedProps: AcceptResourcesProps = {
    ...publicProps,
    form,
    tabIndex,
    handleAccept,
    handleTabChange: (e: React.SyntheticEvent, index: number) => setTabIndex(index),
  };
  return <AcceptResourcesView {...combinedProps} />;
};

export default AcceptResourcesContainer;
