import React from 'react';
import { withStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { ButtonProps } from './Button.props';

import { ButtonStyles } from './Button.styles';

const StyledButton = withStyles(ButtonStyles)(MuiButton);

const ProgressIcon = () => <CircularProgress color='inherit' size={22} sx={{ mr: 0.5 }} />;
const Button: React.FC<ButtonProps> = ({
  alternate,
  neutral,
  progress = false,
  ...props
}: ButtonProps) => (
  <StyledButton
    {...props}
    variant={alternate ? 'outlined' : 'contained'}
    color={neutral ? 'secondary' : props.color}
    startIcon={!progress && props.startIcon}
    endIcon={progress ? <ProgressIcon /> : props.endIcon}
    disableElevation>
    {props.children}
  </StyledButton>
);

export default Button;
