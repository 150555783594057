import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchIncidents, fetchResponses } from 'store/entities/dashboard/dashboardThunk';
import {
  selectIncidentsDB,
  selectLastPageDB,
  selectLoadingDB,
  selectPageDB,
  selectPageLimitDB,
  selectPageTotalDB,
  selectResponsesDB,
  selectProcessing,
} from 'store/entities/dashboard/dashboard.selector';
import { DashboardMainProps, DashboardMainModal, DashboardModalFocus } from './DashboardMain.props';
import { updatePage, resetIncidents } from 'store/entities/dashboard/dashboardSlice';

import DashboardMainView from './DashboardMain.view';
import { IResponsesDB } from 'interfaces/response.interface';
// import { QueryState } from 'common/enums';

const DashboardMain: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const responses = useAppSelector(selectResponsesDB);
  const incidents = useAppSelector(selectIncidentsDB);
  const loading = useAppSelector(selectLoadingDB);
  const isProcessing = useAppSelector(selectProcessing);
  const page = useAppSelector(selectPageDB);
  const pagesTotal = useAppSelector(selectPageTotalDB);
  const pageLimit = useAppSelector(selectPageLimitDB);
  const lastPage = useAppSelector(selectLastPageDB);

  const [modalFocus, setModalFocus] = useState<Partial<DashboardModalFocus>>({
    modal: DashboardMainModal.UNDEFINED,
  });

  useEffect(() => {
    const fetchInitialRecord = dispatch(
      fetchIncidents({ limit: pageLimit, offset: 0, archived: 0 }),
    );
    return () => fetchInitialRecord.abort();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchInitResponse = dispatch(fetchResponses());
    return () => fetchInitResponse.abort();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchResponseRecord = () => dispatch(fetchResponses());
    const fetchIncidentRecord = (num: number) =>
      dispatch(fetchIncidents({ limit: pageLimit, offset: num, archived: 0 }));
    const interval = setTimeout(() => {
      if (!loading && !isProcessing) {
        fetchResponseRecord();
        fetchIncidentRecord(page);
      }
    }, 12000);

    return () => clearInterval(interval);
  }, [incidents, responses]); // eslint-disable-line

  const navigateTo = (path?: string) => {
    navigate([path].filter(Boolean).join('/'));
  };

  const handlePageChange = (num: number) => {
    if (num !== page) {
      dispatch(resetIncidents());
      dispatch(fetchIncidents({ limit: pageLimit, offset: num, archived: 0 }));
      dispatch(updatePage({ PageNum: num }));
    }
  };

  const handleModalFocus = (modal: DashboardMainModal, response?: IResponsesDB) => {
    setModalFocus({ modal, response });
  };

  const combineProps: DashboardMainProps = {
    navigateTo,
    incidents,
    responses,
    page,
    pagesTotal,
    lastPage,
    loading,
    modalFocus,
    handlePageChange,
    handleModalFocus,
  };
  return <DashboardMainView {...combineProps} />;
};

export default DashboardMain;
