import React from 'react';
import Button from 'components/primitives/Button';
import { ArrowForward as ArrowForwardIcon, Send as SendIcon } from '@mui/icons-material';

interface IStepButtonProps {
  lastStep: boolean;
  progress: boolean;
  isDisable: boolean;
  title: string;
  onClick: () => void;
}

export type StepButtonProps = IStepButtonProps;

const StepButton: React.FC<StepButtonProps> = ({
  lastStep,
  progress = false,
  isDisable = false,
  title = 'Send',
  onClick,
}) => {
  return (
    <Button
      style={{ padding: '0 16px' }}
      endIcon={lastStep ? <SendIcon fontSize='small' /> : <ArrowForwardIcon fontSize='small' />}
      progress={progress}
      fullWidth
      disabled={isDisable}
      onClick={onClick}>
      {lastStep ? title : 'Next'}
    </Button>
  );
};

export default StepButton;
