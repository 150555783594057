import { QueryState, ResponseFormStep } from 'common/enums';
import { StackableStep } from 'components/fragments/StackableSteps';
import { IncidentInt } from 'common/interfaces/incident.interface';
import { Nullable } from 'utils/Nullable';

export interface IResponsePageState {
  state: QueryState;
  accessToken: string;
  steps: StackableStep[];
  incident: Nullable<IncidentInt> | undefined;
  error: Nullable<string> | undefined;
  loading: boolean;
  success: boolean;
}

export const ResponsePageEmptyState: IResponsePageState = {
  state: QueryState.UNDEFINED,
  accessToken: '',
  incident: null,
  error: null,
  loading: false,
  success: false,
  steps: [
    {
      step: ResponseFormStep.AMBULANCE,
      title: 'Add ambulance',
      active: true,
    },
    {
      step: ResponseFormStep.PERSONNEL,
      title: 'Add personnel',
      active: false,
    },
  ],
};

export default IResponsePageState;
