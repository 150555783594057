import React from 'react';
import { useWatch } from 'react-hook-form';
import { flatMap } from 'lodash';
import { Checkbox } from '@mui/material';
import { Typo } from 'components/primitives';
import { ResourceType } from 'interfaces/ResourceType';
import { IIncidentAmbulance, IIncidentPersonnel } from 'interfaces/IncidentRequests';

const ResourceTabSnippet: React.FC<{
  name: ResourceType;
  onCheckAll: (name: ResourceType, accept: boolean) => void;
}> = ({ name, onCheckAll }) => {
  const ambulanceTabValue: IIncidentAmbulance[] = useWatch({ name: 'incidents.ambulance' }) || [];
  const personnelTabValue: IIncidentPersonnel[] = useWatch({ name: 'incidents.personnel' }) || [];
  const tabValue = name === 'ambulance' ? ambulanceTabValue : personnelTabValue;
  const items = flatMap(tabValue.map((r) => r.items));
  const checked = items.filter((item) => item?.accepted).length;

  const onToggle = () => {
    const unchecked = checked === items.length;
    onCheckAll(name, !unchecked);
  };

  return (
    <React.Fragment>
      <Typo variant='h6'>
        <Checkbox
          checked={checked > 0}
          sx={{
            opacity: checked > 0 && checked < items.length ? 0.5 : 1,
          }}
          onChange={onToggle}
        />
        {name === 'ambulance' ? 'Ambulance' : 'Personnel'}
      </Typo>
      <Typo variant='body2' fontWeight={600}>
        {checked} / {items.length} items
      </Typo>
    </React.Fragment>
  );
};

export default ResourceTabSnippet;
