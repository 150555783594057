import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import { DashBoardEmptyState, IDashBoardState } from './dashboardState';
import { fetchIncidents, fetchResponses, updateResponse } from './dashboardThunk';

const initialState: IDashBoardState = DashBoardEmptyState;

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updatePage: (state, action: PayloadAction<{ PageNum: number }>) => {
      state.page = action.payload.PageNum;
    },
    resetIncidents: (state) => {
      state.incidents = [];
      state.pagesTotal = 0;
    },
    updateProcessing: (state, action: PayloadAction<{ status: boolean }>) => {
      state.processing = action.payload.status;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIncidents.pending, (state) => {
        state.state = QueryState.AWAIT;
        state.loading = true;
        state.processing = false;
      })
      .addCase(fetchIncidents.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.loading = false;
        state.incidents = action.payload.incidents;
        // state.page = action.payload.page;
        state.lastPage = action.payload.lastPage;
        state.pagesTotal = action.payload.pagesTotal;
      })
      .addCase(fetchIncidents.rejected, (state, action) => {
        state.loading = false;
        state.state = QueryState.FAIL;
        state.error = action.error.message;
      })
      .addCase(fetchResponses.pending, (state) => {
        state.loading = true;
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchResponses.fulfilled, (state, action) => {
        state.loading = false;
        state.state = QueryState.READY;
        state.responses = action.payload;
      })
      .addCase(fetchResponses.rejected, (state) => {
        state.state = QueryState.FAIL;
        state.loading = false;
      })
      .addCase(updateResponse.pending, (state) => {
        state.processing = true;
        state.state = QueryState.AWAIT;
      })
      .addCase(updateResponse.fulfilled, (state) => {
        state.processing = false;
        state.state = QueryState.UPDATED;
      })
      .addCase(updateResponse.rejected, (state, action) => {
        state.processing = false;
        state.state = QueryState.FAIL;
        state.error = action.error.message;
      });
  },
});

export const { updatePage, resetIncidents, updateProcessing } = dashboardSlice.actions;

export default dashboardSlice.reducer;
