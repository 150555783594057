import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueryState } from 'common/enums';
import { fetchArchived } from './archivedThunk';
import { ArchivedEmptyState, IArchivedState } from './ArchivedState';

const initialState: IArchivedState = ArchivedEmptyState;

export const archivedSlice = createSlice({
  name: 'archived',
  initialState,
  reducers: {
    resetPage: (state) => {
      state.archiveList = [];
      state.pageTotal = 0;
    },
    updatePage: (state, action: PayloadAction<{ PageNum: number }>) => {
      state.page = action.payload.PageNum;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchArchived.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchArchived.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.archiveList = action.payload.incidents;
        state.pageTotal = action.payload.pagesTotal;
        state.page = action.payload.page;
        state.lastPage = action.payload.lastPage;
      });
  },
});

export const { resetPage, updatePage } = archivedSlice.actions;

export default archivedSlice.reducer;
