import React from 'react';
import { Controller } from 'react-hook-form';
import useLoginForm from './hooks/useLoginForm';
import useStyles from './Login.styles';
import { LoginProps } from './Login.props';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import { MainLayout, PaperLayout } from 'components/layouts';
import { Typo, FormGroup, TextField, PasswordField } from 'components/primitives';
import { NextButton } from 'components/primitives/Button';

const LoginView: React.FC<LoginProps> = ({
  inProcess,
  errMessage,
  onSubmit,
  handlePasswordReset,
}) => {
  const { control, handleSubmit } = useLoginForm();

  const classes = useStyles();
  return (
    <MainLayout
      footer={
        <Box className={classes.footer}>
          <Typo variant='body1' textAlign='center' color='black'>
            All data in this app is to be considered FOUO and not for public dissemination
          </Typo>
        </Box>
      }>
      <React.Fragment>
        <Typo variant='h1' my={5} textAlign='center'>
          EMS Mobilization
        </Typo>
        <Container maxWidth='xs'>
          <PaperLayout elevation={3} sx={{ p: 3 }}>
            <Box>
              <Controller
                name={'username'}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup label='Email' color='textSecondary' fontWeight={700} error={error}>
                    <TextField
                      error={Boolean(error)}
                      name='username'
                      value={value}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              />
              <Controller
                name={'password'}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormGroup
                    label='Password'
                    color='textSecondary'
                    fontWeight={700}
                    mt={3}
                    error={error}>
                    <PasswordField
                      error={Boolean(error)}
                      name='password'
                      value={value}
                      onChange={onChange}
                    />
                  </FormGroup>
                )}
              />
              {errMessage && (
                <FormHelperText sx={{ mt: 1, lineHeight: '12px' }} error>
                  {errMessage}
                </FormHelperText>
              )}
              <NextButton
                sx={{ mt: errMessage ? 2 : 3, mb: 3 }}
                fullWidth
                onClick={handleSubmit(onSubmit)}
                progress={inProcess}>
                Login
              </NextButton>
              <Divider sx={{ marginBottom: '24px' }} />
              <Box className={classes.links}>
                <Typo
                  variant='subtitle2'
                  className={classes.links}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handlePasswordReset()}>
                  Forgot password
                </Typo>
              </Box>
            </Box>
          </PaperLayout>
        </Container>
      </React.Fragment>
    </MainLayout>
  );
};

export default LoginView;
