import { QueryState } from 'common/enums';
import { Nullable } from 'utils/Nullable';
import { InstitutionFormData } from 'pages/Dashboard/InstitutionDetails/hooks/useInstitutionForm';

export interface IEducationState {
  state: QueryState;
  loading: boolean;
  success: boolean;
  institutionList: InstitutionFormData[] | [];
  institution: Nullable<InstitutionFormData> | undefined;
  page: number;
  lastPage: number;
  pageTotal: number;
  pageLimit: number;
  error: Nullable<string> | undefined;
}

export const EducationEmptyState: IEducationState = {
  state: QueryState.UNDEFINED,
  loading: false,
  success: false,
  institutionList: [],
  institution: null,
  page: 1,
  lastPage: 0,
  pageLimit: parseInt(process.env.REACT_APP_LIST_LIMIT || '10'),
  pageTotal: 0,
  error: null,
};
