import React from 'react';
import { Stack, Grid } from '@mui/material';
import { Typo } from 'components/primitives';

const HeaderTitleAmbulance: React.FC = () => {
  return (
    <Stack>
      <Grid container spacing={1}>
        {/* Header title */}
        <Grid item xs={10}>
          <Typo variant='body2' color='textSecondary' fontWeight={600}>
            Vehicle Description
          </Typo>
        </Grid>
        <Grid item xs={2}>
          <Typo variant='body2' color='textSecondary' fontWeight={600}>
            Code
          </Typo>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default HeaderTitleAmbulance;
