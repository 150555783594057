import { QueryState } from 'common/enums';
import { Nullable } from 'utils/Nullable';
import { IncidentData } from 'interfaces/incident.interface';
import { IResponsesDB } from 'interfaces/response.interface';

export interface IDashBoardState {
  state: QueryState;
  loading: boolean;
  processing: boolean;
  responses: Nullable<IResponsesDB[]> | undefined;
  incidents: Nullable<IncidentData[]> | undefined;
  page: number;
  lastPage: number;
  pagesTotal: number;
  pageLimit: number;
  error: Nullable<string> | undefined;
}

export const DashBoardEmptyState: IDashBoardState = {
  state: QueryState.UNDEFINED,
  loading: false,
  processing: false,
  responses: [],
  incidents: [],
  page: 1,
  lastPage: 0,
  pagesTotal: 0,
  pageLimit: 5,
  error: null,
};
