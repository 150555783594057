import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiInstitutionFilters,
  changeInstitution,
  createInstitution,
  getInstitutionById,
  getInstitutions,
  removeInstitution,
} from 'services/institutionEndPoints';
import { InstitutionInterface } from 'interfaces/institution.interface';
import apiFetch from 'services/apiFetch';
import {
  InstitutionFormData,
  defaultValues,
} from 'pages/Dashboard/InstitutionDetails/hooks/useInstitutionForm';

export const updateRecordInstitution = createAsyncThunk(
  'institution/update',
  async (data: InstitutionFormData) => {
    try {
      const endpoint = changeInstitution(data);
      const response = await apiFetch<InstitutionFormData>(endpoint).then(
        (res) => res?.data || null,
      );
      if (response) {
        return response;
      } else {
        Error('Unable to update record!');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('update catch:', message);
      throw Error(message);
    }
  },
);

export const fetchInstitutions = createAsyncThunk(
  'institution/fetchInstitution',
  async (filters: ApiInstitutionFilters) => {
    try {
      const endpoint = getInstitutions(filters);
      const response = await apiFetch<InstitutionInterface>(endpoint).then(
        (res) => res?.data || null,
      );
      if (response) {
        const pageTotal = response.total;
        const lastPage = response.last_page;
        const page = response.page;
        const institutionList: InstitutionFormData[] = response.data;
        const data = institutionList.map((info) => ({
          ...info,
          cellPhone: info.cellPhone ? info.cellPhone : defaultValues.cellPhone,
          insPOC: info.insPOC?.length === 0 ? defaultValues.insPOC : info.insPOC,
        }));

        return {
          institutionList: data,
          pageTotal,
          lastPage,
          page,
        };
      } else {
        Error('No data found');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchInstitution catch:', message);
      throw Error(message);
    }
  },
);

export const deleteInstitution = createAsyncThunk('institution/delete', async (id: number) => {
  try {
    const endpoint = removeInstitution(id);
    const response = await apiFetch<string>(endpoint).then((res) => res?.data || null);
    if (response) {
      return response;
    } else {
      return Error('Something went wrong!');
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('delete catch:', message);
    throw Error(message);
  }
});

export const fetchInstituteById = createAsyncThunk('institution/fetchById', async (id: number) => {
  try {
    const endpoint = getInstitutionById(id);
    const response = await apiFetch<InstitutionFormData>(endpoint).then((res) => res?.data || null);
    if (response) {
      return {
        ...response,
        city: response.city ? response.city : defaultValues.city,
        state: response.state ? response.state : defaultValues.state,
        county: response.county ? response.county : defaultValues.county,
        zipCode: response.zipCode ? response.zipCode : defaultValues.zipCode,
        address: response.address ? response.address : defaultValues.address,
        cellPhone: response.cellPhone ? response.cellPhone : defaultValues.cellPhone,
        insPOC:
          response.insPOC?.length === 0
            ? defaultValues.insPOC
            : response.insPOC.map((p) => {
                return {
                  ...p,
                  cellPhone: p.cellPhone !== null ? p.cellPhone : '',
                  email: p.email !== null ? p.email : '',
                  fullName: p.fullName !== null ? p.fullName : '',
                };
              }),
      };
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchById catch:', message);
    throw Error(message);
  }
});

export const addInstitution = createAsyncThunk(
  'institution/add',
  async (data: InstitutionFormData) => {
    try {
      const endpoint = createInstitution(data);
      const response = await apiFetch<InstitutionFormData>(endpoint).then(
        (res) => res?.data || null,
      );
      if (response) {
        return {
          ...response,
          insPOC: response.insPOC?.length === 0 ? defaultValues.insPOC : response.insPOC,
        };
      } else {
        Error('Something went wrong!');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('add catch:', message);
      throw Error(message);
    }
  },
);
