import { Theme } from '@mui/material';
// eslint-disable-next-line
const ButtonStyles = (theme: Theme): any => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    // fontSize: 16,
    padding: '8px 16px',
    border: '2px solid',
    lineHeight: 1.5,
    color: '#DD2020',
    backgroundColor: '#ffff',
    borderColor: '#FE9090',
    '&:hover': {
      borderColor: '#DD2020',
      backgroundColor: '#ffff',
      boxShadow: 'none',
    },
  },
});

export { ButtonStyles };
