import DefaultPaletteOptions from '../defaultPalette';

const pallet: DefaultPaletteOptions = {
  primary: {
    light: '#90ADFE', // light blue
    main: '#2961FD', // main blue
    dark: '#01195B', // dark blue
  },
  secondary: {
    light: '#0066ff',
    main: '#0044ff',
    contrastText: '#ffcc00',
  },
  text: {
    primary: '#01195B',
    secondary: '#767676',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};

export default pallet;
