import React from 'react';
import { AddCircleRounded as AddCircleRoundedIcon } from '@mui/icons-material';
import Box, { BoxProps } from '@mui/material/Box';
// import { CircularProgress } from '@mui/material';
import { Button, Typo } from 'components/primitives';

const EmptyIncident: React.FC<{ onCreate: () => void } & BoxProps> = ({ onCreate, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px',
      }}
      {...props}>
      <Box sx={{ textAlign: 'center' }}>
        <Typo variant='h4' color='textSecondary'>
          There is no incident created yet
        </Typo>
        <Typo variant='h6' fontWeight={400} color='textSecondary'>
          Please use the button below to create an incident
        </Typo>
        <Button
          sx={{ mt: 2, alignItems: 'center' }}
          startIcon={<AddCircleRoundedIcon />}
          onClick={onCreate}>
          Create Incident
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyIncident;
