import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokenState, UserAuthEmptyState, UserInfoAuth } from './TokenState';
import { userLogin, changePassword } from './tokenActions';

const initialState: ITokenState = UserAuthEmptyState;

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserInfoAuth>) => {
      state.user = action.payload;
    },
    updateUserEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.email = action.payload.email;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        state.authorization = action.payload?.authorization;
        state.createdAt = action.payload?.createdAt;
        state.ttl = action.payload?.ttl;
        state.scope = action.payload?.scope;
        state.user = action.payload?.user;
      })
      .addCase(userLogin.rejected, () => {
        return initialState;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.authorization = action.payload?.authorization;
        state.createdAt = action.payload?.createdAt;
        state.ttl = action.payload?.ttl;
        state.scope = action.payload?.scope;
        state.user = action.payload?.user;
        state.email = action.payload?.email;
      })
      .addCase(changePassword.rejected, () => {
        return initialState;
      });
  },
});

export const { updateUser, updateUserEmail } = tokenSlice.actions;
export default tokenSlice.reducer;
