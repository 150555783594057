import { ISelectProp } from 'interfaces/SelectProp';
import { IUserInfo } from './hooks/useUserForm';
import { UseFormReturn, SubmitHandler } from 'react-hook-form';

export const UserTypes: ISelectProp[] = [
  {
    label: 'EMS Coordinator',
    value: 'coordinator',
  },
  {
    label: 'EMS Administrator',
    value: 'administrator',
  },
];

interface IUserManage {
  /** Props here */
  form: UseFormReturn<IUserInfo, any>; // eslint-disable-line
  usersList: IUserInfo[];
  emsSelectOptions: ISelectProp[];
  loading: boolean;
  addUserModal: boolean;
  page: number;
  pageTotal: number;
  handleModal: (show: boolean) => void;
  onConfirm: SubmitHandler<IUserInfo>;
  onNavigate: (id: string) => void;
  handlePageChange: (num: number) => void;
}

export type UserManageProps = IUserManage;
