import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CustomIcon(props: SvgIconProps) {
  return (
    <div style={{ position: 'relative' }}>
      <SvgIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} {...props}>
        <path d='M0.5 0.5H15.5V3.83333H0.5V0.5ZM1.33333 4.66667H14.6667V15.5H1.33333V4.66667ZM5.91667 7.16667C5.80616 7.16667 5.70018 7.21057 5.62204 7.2887C5.5439 7.36685 5.5 7.47283 5.5 7.58333V8.83333H10.5V7.58333C10.5 7.47283 10.4561 7.36685 10.378 7.2887C10.2998 7.21057 10.1938 7.16667 10.0833 7.16667H5.91667Z' />
      </SvgIcon>
    </div>
  );
}

export default CustomIcon;
