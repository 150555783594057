// import { UserAccountType } from 'common/enums';
import { Nullable } from 'utils/Nullable';

export type UserInfoAuth = {
  id?: string;
  email: string;
  agency: string;
  name: string;
  account: string;
  workNumber?: Nullable<string>;
  mobileNumber?: Nullable<string>;
};

export interface ITokenState {
  authorization: string | null | undefined;
  ttl: number | undefined;
  createdAt: number | undefined;
  scope: string | undefined;
  user: Nullable<UserInfoAuth> | undefined;
  email: string | undefined;
}

export const UserAuthEmptyState: ITokenState = {
  authorization: null,
  ttl: 0,
  createdAt: 0,
  scope: '',
  user: null,
  email: undefined,
};
