import { IncidentData } from 'interfaces/incident.interface';
import { IResponsesDB } from 'interfaces/response.interface';
import { Nullable } from 'utils/Nullable';

export enum DashboardMainModal {
  UNDEFINED = 0,
  ACCEPT = 1,
  DECLINE = 2,
}

export interface DashboardModalFocus {
  modal: DashboardMainModal;
  response: IResponsesDB;
}

interface DashboardMainPublicProps {
  /** public props */
  navigateTo: (path?: string) => void;
  incidents: Nullable<IncidentData[]> | undefined;
  responses: Nullable<IResponsesDB[]> | undefined;
  page: number;
  pagesTotal: number;
  lastPage: number;
  loading: boolean;
  modalFocus: Partial<DashboardModalFocus>;
  handlePageChange: (num: number) => void;
  handleModalFocus: (modal: DashboardMainModal, response?: IResponsesDB) => void;
}

export type DashboardMainProps = DashboardMainPublicProps;
