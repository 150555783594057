import React from 'react';
import clsx from 'clsx';
import { Button, Stack, Theme, useTheme } from '@mui/material';
import { Typo } from 'components/primitives';
import { StepBoxSnippet } from 'components/snippets';
import { StackableSteps } from './StackableSteps.props';
import useStyles from './StackableSteps.styles';

const StackableStepsView: React.FC<StackableSteps> = ({
  active = null,
  steps,
  spacing = 1,
  onStep,
  ...props
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const onTouchStep = (step: number | string) => () => {
    onStep && onStep(step);
  };

  return (
    <Stack {...props} spacing={spacing}>
      {steps.map((s, index: number) => {
        const isActive = s.active || s.complete || s.step === active;
        const doneColor = theme.palette.primary.main;
        const color = isActive ? doneColor : 'textPrimary';

        return (
          <Button
            key={s.step}
            className={clsx(classes.step, isActive ? classes.activeStep : null)}
            onClick={onTouchStep(s.step)}
            disabled={!isActive}
            disableTouchRipple={!isActive}>
            <StepBoxSnippet step={index + 1} complete={s.complete} />
            <Typo
              variant='body2'
              color={isActive ? color : 'textSecondary'}
              fontWeight={isActive ? 600 : 400}>
              {s.title || s.step}
            </Typo>
          </Button>
        );
      })}
    </Stack>
  );
};

export default StackableStepsView;
