import React, { useState, useEffect } from 'react';
import { FieldValues, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme, Checkbox, Paper } from '@mui/material';
import { uniq } from 'lodash';

import { Typo } from 'components/primitives';
import useStyles from './RegionsCard.styles';

const RegionsCardView: React.FC<
  {
    name: string;
    title: string;
    counties: string[];
    watch: UseFormWatch<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
  } & BoxProps
> = ({ name, title, counties, watch, setValue, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [checked, setChecked] = useState([false, false]);
  const selectedCounties = watch(name);

  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    const checkHasAll = (arr: string[], target: string[]) => target.every((v) => arr.includes(v));
    const checkSome = (arr: string[], target: string[]) => target.some((v) => arr.includes(v));

    const updateCheckBoxStatus = () => {
      if (selectedCounties.length === 0) return setChecked([false, false]);

      if (checkHasAll(selectedCounties, counties)) {
        return setChecked([true, true]);
      }

      if (checkSome(selectedCounties, counties)) {
        return setChecked([true, false]);
      }
    };

    updateCheckBoxStatus();
  }, [selectedCounties, counties]);

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, event.target.checked]);
    let currCountyValue: string[] = watch(name); // get the current county list
    if (event.target.checked) {
      // Add all counties from checked Region
      currCountyValue = currCountyValue.concat(counties);
      currCountyValue = uniq(currCountyValue);
      setValue(name, currCountyValue);
    } else {
      //  Remove all counties from specified Region
      const filteredCounties: string[] = [];
      currCountyValue.map((option) => {
        const idx = counties.findIndex((county) => county === option);
        if (idx === -1) filteredCounties.push(option);
      });
      setValue(name, filteredCounties);
    }
    currCountyValue = currCountyValue.concat(counties);
  };

  return (
    <Box {...props}>
      <Paper className={classes.root}>
        <Box className={classes.titleContainer}>
          <Typo variant='h6' color={theme.palette.primary.main}>
            {title}
          </Typo>
          <Checkbox
            size='small'
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleCheckBox}
          />
        </Box>
        <Box className={classes.countiesWrapper}>
          <Typo variant='body2'>{counties.join(', ')}</Typo>
        </Box>
      </Paper>
    </Box>
  );
};

export default RegionsCardView;
