import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CustomIcon(props: SvgIconProps) {
  return (
    <div style={{ position: 'relative' }}>
      <SvgIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} {...props}>
        <path d='M1.83333 0C1.09333 0 0.5 0.593333 0.5 1.33333V10.6667C0.5 11.0203 0.640476 11.3594 0.890524 11.6095C1.14057 11.8595 1.47971 12 1.83333 12H11.1667C11.5203 12 11.8594 11.8595 12.1095 11.6095C12.3595 11.3594 12.5 11.0203 12.5 10.6667V6H11.1667V10.6667H1.83333V1.33333H6.5V0H1.83333ZM10.3533 0.666667C10.2334 0.667679 10.1185 0.715525 10.0333 0.8L9.22 1.60667L10.8867 3.27333L11.7 2.46667C11.8733 2.29333 11.8733 2 11.7 1.83333L10.6667 0.8C10.58 0.713333 10.4667 0.666667 10.3533 0.666667ZM8.74667 2.08L3.83333 7V8.66667H5.5L10.4133 3.74667L8.74667 2.08Z' />
      </SvgIcon>
    </div>
  );
}

export default CustomIcon;
