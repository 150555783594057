import { createSlice } from '@reduxjs/toolkit';
import { IIncidentDetailState, IncidentDetailEmptyState } from './incidentdetailState';
import {
  fetchIncidentById,
  fetchResponsesByIncident,
  fetchRequests,
  execArchiveIncident,
  remindAgency,
  remindAll,
} from './incidentdetailThunk';
import { QueryState } from 'common/enums';

const initialState: IIncidentDetailState = IncidentDetailEmptyState;

export const incidentDetailSlice = createSlice({
  name: 'incidentDetail',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIncidentById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchIncidentById.fulfilled, (state, action) => {
        state.loading = false;
        state.incident = action.payload || null;
      })
      .addCase(fetchIncidentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(fetchResponsesByIncident.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchResponsesByIncident.fulfilled, (state, action) => {
        state.loading = false;
        state.responses = action.payload || [];
      })
      .addCase(fetchResponsesByIncident.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(fetchRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.requests = action.payload;
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(execArchiveIncident.pending, (state) => {
        state.processing = true;
        state.serviceAction = QueryState.ARCHIVED;
      })
      .addCase(execArchiveIncident.fulfilled, (state, action) => {
        state.processing = false;
        state.incident = action.payload;
        state.serviceAction = QueryState.UNDEFINED;
      })
      .addCase(execArchiveIncident.rejected, (state, action) => {
        state.processing = false;
        state.error = action.error.message;
        state.serviceAction = QueryState.UNDEFINED;
      })
      .addCase(remindAgency.pending, (state) => {
        state.serviceAction = QueryState.NOTIFICATION;
        state.processing = true;
      })
      .addCase(remindAgency.fulfilled, (state) => {
        state.serviceAction = QueryState.READY;
        state.processing = false;
      })
      .addCase(remindAgency.rejected, (state, action) => {
        state.serviceAction = QueryState.FAIL;
        state.processing = false;
        state.error = action.error.message;
      })
      .addCase(remindAll.pending, (state) => {
        state.serviceAction = QueryState.NOTIFICATION;
        state.processing = true;
      })
      .addCase(remindAll.fulfilled, (state) => {
        state.serviceAction = QueryState.READY;
        state.processing = false;
      })
      .addCase(remindAll.rejected, (state, action) => {
        state.serviceAction = QueryState.FAIL;
        state.processing = false;
        state.error = action.error.message;
      });
  },
});

export const { resetState } = incidentDetailSlice.actions;

export default incidentDetailSlice.reducer;
