import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgenciesEmptyState, IAgenciesState } from './AgenciesState';
import { AgencyFormData } from 'pages/Dashboard/AgencyDetails/hooks/useAgencyForm';
import {
  createAgency,
  changeAgency,
  fetchAgencies,
  fetchMainAgenciesList,
  fetchAgencyNames,
  fetchAgencyEdit,
  removeAgency,
  fetchAgencyById,
} from './agenciesThunk';
import { QueryState } from 'common/enums';

const initialState: IAgenciesState = AgenciesEmptyState;

export const agenciesSlice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    updateAgency: (state, action: PayloadAction<AgencyFormData>) => {
      state.agency = action.payload;
    },
    resetAgencies: (state) => {
      state.agencies = [];
      state.pageTotal = 0;
    },
    updatePage: (state, action: PayloadAction<{ PageNum: number }>) => {
      state.page = action.payload.PageNum;
    },
    resetState: (state, action: PayloadAction<{ value: QueryState }>) => {
      state.state = action.payload.value;
    },
    updateMagicToken: (state, action: PayloadAction<{ token: string }>) => {
      state.accessToken = action.payload.token;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createAgency.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(createAgency.fulfilled, (state) => {
        state.state = QueryState.CREATED;
      })
      .addCase(createAgency.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        if (action.error.message === 'ER_DUP_ENTRY') {
          state.isEmailDuplicate = true;
        } else {
          state.error = action.error.message || 'Something went wrong!';
        }
      })
      .addCase(changeAgency.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(changeAgency.fulfilled, (state) => {
        state.state = QueryState.UPDATED;
      })
      .addCase(changeAgency.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        if (action.error.message === 'ER_DUP_ENTRY') {
          state.isEmailDuplicate = true;
        } else {
          state.error = action.error.message || 'Something went wrong!';
        }
      })
      .addCase(removeAgency.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(removeAgency.fulfilled, (state) => {
        state.state = QueryState.DELETED;
      })
      .addCase(removeAgency.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(fetchAgencies.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.pageTotal = action.payload?.pageTotal || state.pageTotal;
        state.agencies = action.payload?.agencies || state.agencies;
        state.page = action.payload?.pageTotal === 1 ? 1 : state.page;
      })
      .addCase(fetchMainAgenciesList.fulfilled, (state, action) => {
        state.mainAgencyOptions = action.payload?.data || [];
      })
      .addCase(fetchAgencyNames.fulfilled, (state, action) => {
        state.agencyNameOptions = action.payload?.data || [];
      })
      .addCase(fetchAgencyEdit.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(fetchAgencyEdit.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.agency = action.payload;
      })
      .addCase(fetchAgencyEdit.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(fetchAgencyById.pending, (state) => {
        state.state = QueryState.AWAIT;
        state.agency = initialState.agency;
      })
      .addCase(fetchAgencyById.fulfilled, (state, action) => {
        state.state = QueryState.READY;
        state.agency = action.payload;
      })
      .addCase(fetchAgencyById.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export const { updateAgency, resetAgencies, updatePage, resetState, updateMagicToken } =
  agenciesSlice.actions;

export default agenciesSlice.reducer;
