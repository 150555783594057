import { useForm } from 'react-hook-form';
import { IResponsesDB } from 'interfaces/response.interface';
// import { useMemo } from 'react';

function useResourceForm() {
  const ResourcesForm = useForm<IResponsesDB>();

  return ResourcesForm;
}

export default useResourceForm;
