import React from 'react';
import { Stack, Grid } from '@mui/material';
import { Typo } from 'components/primitives';

const HeaderTitlePersonnel: React.FC = () => {
  return (
    <Stack>
      <Grid container spacing={6}>
        {/* Header title */}
        <Grid item xs={8}>
          <Typo variant='body2' color='textSecondary' fontWeight={600}>
            Name
          </Typo>
        </Grid>
        <Grid item xs={2}>
          <Typo variant='body2' color='textSecondary' fontWeight={600}>
            ID Number
          </Typo>
        </Grid>
        <Grid item xs={2}>
          <Typo variant='body2' color='textSecondary' fontWeight={600}>
            Rate of pay
          </Typo>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default HeaderTitlePersonnel;
