import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    paperWrapper: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: '#fafafa',
    },
  };
});

export default useStyles;
