import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CustomIcon(props: SvgIconProps) {
  return (
    <div style={{ position: 'relative' }}>
      <SvgIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-30%)' }} {...props}>
        <path
          d='M0.333344 0V12H4.33334V9.66667H5.66668V12H9.66668V0H0.333344ZM1.66668 1.33333H3.00001V2.66667H1.66668V1.33333ZM4.33334 1.33333H5.66668V2.66667H4.33334V1.33333ZM7.00001 1.33333H8.33334V2.66667H7.00001V1.33333ZM1.66668 4H3.00001V5.33333H1.66668V4ZM4.33334 4H5.66668V5.33333H4.33334V4ZM7.00001 4H8.33334V5.33333H7.00001V4ZM1.66668 6.66667H3.00001V8H1.66668V6.66667ZM4.33334 6.66667H5.66668V8H4.33334V6.66667ZM7.00001 6.66667H8.33334V8H7.00001V6.66667ZM1.66668 9.33333H3.00001V10.6667H1.66668V9.33333ZM7.00001 9.33333H8.33334V10.6667H7.00001V9.33333Z'
          fill='currentColor'
        />
      </SvgIcon>
    </div>
  );
}

export default CustomIcon;
