import React, { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import moment from 'moment';
import { sumBy } from 'lodash';
import { toast } from 'react-toastify';
import { Box, Container, Fade, Pagination, Stack } from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { updateResponse, fetchResponses } from 'store/entities/dashboard/dashboardThunk';
import useWindowSize from 'common/hook/useWindowSize';
import { ISize } from 'interfaces/Size';
import { Modal, TitleText, ToastMessage, Typo } from 'components/primitives';
import useStyles from './DashboardMain.styles';
import {
  EmptyIncidentSnippet,
  RequestResource,
  ResponseCard,
  PreLoaderSnippet,
} from 'components/snippets';
import { DashboardMainModal, DashboardMainProps } from './DashboardMain.props';
import { AcceptResources, DeclineResources, RequestItemBox } from 'components/fragments';
import { IResponsesDB } from 'interfaces/response.interface';
import { ResponseStatus } from 'common/enums';
import { ToastStatus } from 'common/enums/ToastStatus';
import { formatDeclineResources } from 'common/utils';
import { IIncidentAmbulance, IIncidentPersonnel } from 'interfaces/IncidentRequests';

const emailTemplate =
  'Attention Director and/or point of contact personnel, thank you for your support and offer ' +
  'for the requested EMS Mobilization response. At this time, the response is full. ' +
  'Please continue to monitor for further requests as this incident continues to expand. ' +
  'Thank you for your continued support.';
const DashboardMainView: React.FC<DashboardMainProps> = ({
  loading,
  incidents,
  responses,
  modalFocus,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const windowSize: ISize = useWindowSize();
  const classes = useStyles(windowSize);
  const isIncidentsEmpty = Array.isArray(incidents) && incidents.length === 0;
  const ttlIncidentCount = props.pagesTotal;
  const filterResponses = Array.isArray(responses) ? responses : [];
  const countResponses = filterResponses.length || 0;

  const [emailMsg, setEmailMsg] = useState<string>(emailTemplate);

  const onPerformResponse = (accept: boolean, response: IResponsesDB) => {
    if (accept) {
      props.handleModalFocus(DashboardMainModal.ACCEPT, response);
    } else {
      props.handleModalFocus(DashboardMainModal.DECLINE, response);
    }
  };

  const onAcceptResources = async (
    ok: boolean,
    payload: IResponsesDB,
    original: IResponsesDB | undefined,
    status: DashboardMainModal,
  ) => {
    try {
      if (ok) {
        const details = payload.incidents;
        const orgAmbulance = original?.incidents.ambulance || [];
        const resAmbulance = details.ambulance;
        const orgPersonnel = original?.incidents.personnel || [];
        const resPersonnel = details.personnel;

        for (let i = 0; i < orgAmbulance?.length; i++) {
          const el = orgAmbulance[i];
          resAmbulance[i].id = el.id;
        }
        for (let i = 0; i < orgPersonnel?.length; i++) {
          const el = orgPersonnel[i];
          resPersonnel[i].id = el.id;
        }

        const responsePayload: IResponsesDB = {
          ...payload,
          incidents: {
            ...details,
            ambulance: resAmbulance,
            personnel: resPersonnel,
          },
          status,
        };

        await dispatch(
          updateResponse({ response: responsePayload, status: ResponseStatus.ACCEPTED }),
        ).unwrap();

        await dispatch(fetchResponses());

        toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Process' />);
        props.handleModalFocus(DashboardMainModal.UNDEFINED);
      } else {
        props.handleModalFocus(DashboardMainModal.UNDEFINED);
      }
    } catch {
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
    }
  };

  const onDeclineResources = async (
    ok: boolean,
    payload: IResponsesDB,
    status: DashboardMainModal,
  ) => {
    try {
      if (ok) {
        const ambulance = formatDeclineResources<IIncidentAmbulance>(payload.incidents.ambulance);
        const personnel = formatDeclineResources<IIncidentPersonnel>(payload.incidents.personnel);
        const responsePayload: IResponsesDB = {
          ...payload,
          incidents: {
            ...payload.incidents,
            ambulance,
            personnel,
          },
          status,
          declineMessage: emailMsg,
        };

        await dispatch(
          updateResponse({ response: responsePayload, status: ResponseStatus.REJECTED }),
        ).unwrap();

        await dispatch(fetchResponses());

        toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Process' />);
        props.handleModalFocus(DashboardMainModal.UNDEFINED);
      } else {
        props.handleModalFocus(DashboardMainModal.UNDEFINED);
      }
    } catch {
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
    }
  };

  return (
    <>
      {countResponses > 0 && (
        <Container>
          <TitleText title='Latest responses'>
            <Box className={classes.responseBadge}>
              <Typo variant='h4'>{countResponses}</Typo>
            </Box>
          </TitleText>
        </Container>
      )}
      <Fade in={countResponses > 0}>
        <Box sx={{ height: 'auto', width: '100%' }}>
          <Box className={classes.responseContainer}>
            <ScrollContainer className={classes.responseList} horizontal>
              {filterResponses.map((r, idx) => (
                <ResponseCard
                  key={r?.id || idx}
                  sx={{ mb: 1 }}
                  className={classes.responseItem}
                  incidentResponse={r}
                  onAccept={(x: boolean, y: IResponsesDB) => onPerformResponse(x, y)}
                />
              ))}
            </ScrollContainer>
          </Box>
        </Box>
      </Fade>
      <Container component='main'>
        <TitleText title='Incidents'>
          {!isIncidentsEmpty && (
            <Box className={classes.responseBadge}>
              <Typo variant='h4'>{ttlIncidentCount}</Typo>
            </Box>
          )}
        </TitleText>
        <PreLoaderSnippet
          visible={loading && isIncidentsEmpty}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
          }}
        />
        <Fade in={isIncidentsEmpty && !loading}>
          <Box>
            {isIncidentsEmpty && (
              <EmptyIncidentSnippet onCreate={() => props.navigateTo('incident/new')} />
            )}
          </Box>
        </Fade>
        <Fade in={!isIncidentsEmpty}>
          <Box>
            <Stack spacing={1}>
              {incidents &&
                incidents.map((i, idx) => {
                  const createdAt = moment(i.createdAt).format('YYYY / MM / DD');
                  const counties = i.counties.length || 0;
                  const ambulance = i.ambulance || [];
                  const personnel = i.personnel || [];
                  const reqPersonnel = sumBy(personnel, (value) => Number(value.number) || 0);
                  const reqAmbulance = sumBy(ambulance, (value) => Number(value.number) || 0);
                  const acceptedPersonnel = personnel
                    .flatMap((p) => {
                      const pa = [];
                      pa.push(p.items?.filter((i) => i.accepted));
                      return pa;
                    })
                    .flat();
                  const acceptedAmbulance = ambulance
                    .flatMap((a) => {
                      const am = [];
                      am.push(a.items?.filter((i) => i.accepted));
                      return am;
                    })
                    .flat();
                  const ttlApprovePer = acceptedPersonnel.length || 0;
                  const ttlApproveAmb = acceptedAmbulance.length || 0;
                  const numResp = i.responses?.length || 0;
                  return (
                    <RequestItemBox
                      onClick={() => props.navigateTo(`incident/${i.id}`)}
                      sx={{ cursor: 'pointer' }}
                      key={i.id || idx}
                      name={`Request #${i.requestId}`}
                      meta={[
                        { label: 'Created on', value: createdAt },
                        {
                          label: 'Sent to:',
                          value: `${counties} Counties`,
                        },
                        { label: 'Responses:', value: JSON.stringify(numResp) },
                      ]}>
                      <RequestResource
                        title='Personnel'
                        meta={[
                          {
                            label: 'Requested',
                            value: reqPersonnel,
                          },
                          { label: 'Accepted', value: ttlApprovePer },
                        ]}
                      />
                      <RequestResource
                        title='Ambulance'
                        meta={[
                          { label: 'Requested', value: reqAmbulance },
                          { label: 'Accepted', value: ttlApproveAmb },
                        ]}
                      />
                    </RequestItemBox>
                  );
                })}
            </Stack>
          </Box>
        </Fade>
        {props.lastPage > 1 && (
          <Pagination
            className={classes.pagination}
            count={props.lastPage}
            page={props.page}
            shape='rounded'
            color='primary'
            onChange={(e, num) => props.handlePageChange(num)}
          />
        )}
      </Container>

      <Modal open={modalFocus.modal === DashboardMainModal.DECLINE}>
        <DeclineResources
          incidentResponse={modalFocus.response}
          onOk={(confirm, payload) =>
            onDeclineResources(confirm, payload, DashboardMainModal.DECLINE)
          }
          emailMsg={emailMsg}
          changeTextEmailBody={(value) => setEmailMsg(value)}
        />
      </Modal>
      <Modal open={modalFocus.modal === DashboardMainModal.ACCEPT}>
        <AcceptResources
          incidentResponse={modalFocus.response}
          onAccept={(x: boolean, payload) =>
            onAcceptResources(x, payload, modalFocus?.response, DashboardMainModal.ACCEPT)
          }
        />
      </Modal>
    </>
  );
};

export default DashboardMainView;
