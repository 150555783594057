import React, { useState } from 'react';
import { ProvidedResourcesCalcProps, ProvidedResourcesProps } from './ProvidedResources.props';
import ProvidedResourcesView from './ProvidedResources.view';

const ProvidedResourcesContainer: React.FC<ProvidedResourcesCalcProps> = (publicProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const combinedProps: ProvidedResourcesProps = {
    ...publicProps,
    tabIndex,
    handleTabChange: (_, index: number) => setTabIndex(index),
  };
  return <ProvidedResourcesView {...combinedProps} />;
};

export default ProvidedResourcesContainer;
