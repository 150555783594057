import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: '2px',
      fontWeight: '600',
    },
    main: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffff',
    },
    secondary: {
      backgroundColor: '#D8D8D8',
      color: '#1A1A1A',
    },
  };
});

export default useStyles;
