import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RequestFormStep } from 'common/enums';
import { Nullable } from 'utils/Nullable';
import { IIncidentAmbulance, IIncidentPersonnel } from 'interfaces/IncidentRequests';
import { toShortDateOnly } from 'common/utils/DateTime';

interface RequestFormResources {
  ambulance: IIncidentAmbulance[];
  personnel: IIncidentPersonnel[];
}

export interface RequestFormRegion {
  counties: string[];
  serviceType: Nullable<string[]>;
}

export interface RequestFormData {
  id?: string;
  incidentDetails: {
    missionType: string;
    requestId: number | string | undefined;
    date: string;
    departmentName: string;
    contactPerson: string;
    contactPhone: string;
    stagingLocation: string;
    destination: Nullable<string> | undefined;
    notes: string;
  };
  resources: RequestFormResources;
  regions: RequestFormRegion;
  emailBody: string;
  step: RequestFormStep;
}

export const defaultRequestFormData = {
  step: RequestFormStep.INCIDENT_DETAILS,
  incidentDetails: {
    missionType: 'evacuate',
    requestId: 0,
    date: toShortDateOnly(new Date()),
    departmentName: '',
    contactPerson: '',
    contactPhone: '',
    stagingLocation: '',
    destination: '',
    notes: '',
  },
  regions: {
    counties: [],
    serviceType: [],
  },
  resources: {
    ambulance: [
      {
        number: 0,
        vehicleDescription: '',
        comments: '',
      },
    ],
    personnel: [
      {
        number: 0,
        type: '',
        description: '',
      },
    ],
  },
  emailBody:
    'Attention Director and/or point of contact personnel,\n\n EMS Mobilization is requesting the following to support a need identified by a local resource request.',
};

function useRequestForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        incidentDetails: yup.mixed().when('step', {
          is: (step: string) => step === RequestFormStep.INCIDENT_DETAILS,
          then: yup.object().shape({
            missionType: yup.string().required('This field is required!').max(255),
            requestId: yup.number().required('This field is required!').max(2147483647),
            date: yup.date().required('This field is required!'),
            departmentName: yup.string().required('This field is required!').max(255),
            contactPerson: yup.string().required('This field is required!').max(255),
            contactPhone: yup
              .string()
              .min(12, 'Required a 10 digit number')
              .required('This field is required!'),
            stagingLocation: yup.string().required('This field is required!').max(255),
            destination: yup.string().nullable(true).max(255),
            notes: yup.string().max(65535),
          }),
        }),
        resources: yup
          .mixed()
          .when('step', {
            is: (step: string) => step === RequestFormStep.AMBULANCE,
            then: yup.object().shape({
              ambulance: yup.array().of(
                yup.object().shape({
                  number: yup.number().test({
                    name: 'overLimit',
                    message: 'Maximum limit to 30 and Minimum of 1',
                    test: (number: unknown) => {
                      const transform: any = (val: unknown) => (val !== '' ? Number(val) : null); //  eslint-disable-line
                      return transform(number) <= 30;
                    },
                  }),
                  vehicleDescription: yup.string().when('number', {
                    is: (number: unknown) => {
                      return Number(number) > 0;
                    },
                    then: yup.string().required('This is required!'),
                  }),
                  comments: yup.string().max(65535),
                }),
              ),
            }),
          })
          .when('step', {
            is: (step: string) => step === RequestFormStep.PERSONNEL,
            then: yup.object().shape({
              personnel: yup.array().of(
                yup.object().shape({
                  number: yup.number().test({
                    name: 'overLimit',
                    message: 'Maximum limit to 30 and Minimum of 1',
                    test: (number: unknown) => {
                      const transform: any = (val: unknown) => (val !== '' ? Number(val) : null); // eslint-disable-line
                      return transform(number) <= 30;
                    },
                  }),
                  description: yup.string().max(255),
                  type: yup.string().when('number', {
                    is: (number: number) => {
                      return Number(number) > 0;
                    },
                    then: yup.string().required('This is required!'),
                  }),
                }),
              ),
            }),
          })
          .when('step', {
            is: (step: string) => step === RequestFormStep.PERSONNEL,
            then: yup.object().test(
              'atLeastOneResource',
              'Please enter at least one Ambulance or Personnel!', // eslint-disable-next-line
              (value: any & RequestFormResources) => {
                const personnel = value?.personnel;
                const ambulance = value?.ambulance;
                if (personnel.length > 0 || ambulance.length > 0) {
                  const someP = personnel.some((p: IIncidentPersonnel) => Number(p.number) > 0);
                  const someA = ambulance.some((a: IIncidentAmbulance) => Number(a.number) > 0);
                  if (someA || someP) return true;
                }
                return false;
              },
            ),
          }),
        regions: yup.mixed().when('step', {
          is: (step: string) => step === RequestFormStep.REGIONS,
          then: yup.object().shape({
            counties: yup.array().min(1, 'Select at least one county'),
            serviceType: yup.array(),
          }),
        }),
      }),
    [],
  );

  return useForm<RequestFormData>({
    defaultValues: defaultRequestFormData,
    resolver: yupResolver(validationSchema),
  });
}

export default useRequestForm;
