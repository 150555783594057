import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
import { ApiIncidentsFilters } from 'services/incidentEndPoints';
import { InstitutionFormData } from 'pages/Dashboard/InstitutionDetails/hooks/useInstitutionForm';

export interface ApiInstitutionFilters {
  limit?: number;
  offset?: number;
  keyword?: string;
  county?: string;
}

function setInstitutionParams(filters: ApiInstitutionFilters): ApiIncidentsFilters {
  const params: ApiInstitutionFilters = {};
  if (filters.offset || filters.offset === 0) {
    params.offset = filters.offset;
  }

  if (filters.limit) {
    params.limit = filters.limit;
  }

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (filters.county) {
    params.county = filters.county.trim();
  }

  return params;
}

export const getInstitutions = (filters: ApiInstitutionFilters): IEndpoint => {
  const params = setInstitutionParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'institution',
    params,
  };
};

export const getInstitutionById = (id: number): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `institution/${id}`,
  };
};

export const changeInstitution = (data: InstitutionFormData): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'institution',
    data,
  };
};

export const removeInstitution = (id: number): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: 'institution/' + id,
  };
};

export const createInstitution = (data: InstitutionFormData): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'institution',
    data,
  };
};
