import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CustomIcon(props: SvgIconProps) {
  return (
    <div style={{ position: 'relative' }}>
      <SvgIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} {...props}>
        <path d='M7.08663 9.39984C7.34044 8.84209 7.70165 8.33982 8.14961 7.9217C8.59758 7.50357 9.12353 7.1778 9.69742 6.96298C10.2713 6.74815 10.8819 6.64849 11.4943 6.66969C12.1067 6.69088 12.709 6.83252 13.2666 7.0865C13.8244 7.34032 14.3266 7.70152 14.7448 8.14949C15.1629 8.59746 15.4887 9.1234 15.7035 9.69729C15.9183 10.2712 16.018 10.8818 15.9968 11.4942C15.9756 12.1066 15.8339 12.7088 15.58 13.2665C15.2103 14.0825 14.613 14.7745 13.8597 15.2594C13.1064 15.7443 12.2291 16.0014 11.3333 15.9998C9.53329 15.9998 7.88663 14.9598 7.11329 13.3332H0.666626V11.9998C0.706626 11.2398 1.22663 10.6198 2.22663 10.1198C3.22663 9.61984 4.47996 9.35984 5.99996 9.33317C6.37996 9.33317 6.73996 9.3665 7.08663 9.39984M5.99996 2.6665C6.74663 2.6865 7.37329 2.9465 7.87329 3.4465C8.37329 3.9465 8.61996 4.57317 8.61996 5.33317C8.61996 6.09317 8.37329 6.71984 7.87329 7.21984C7.37329 7.71984 6.74663 7.9665 5.99996 7.9665C5.25329 7.9665 4.62663 7.71984 4.12663 7.21984C3.62663 6.71984 3.37996 6.09317 3.37996 5.33317C3.37996 4.57317 3.62663 3.9465 4.12663 3.4465C4.62663 2.9465 5.25329 2.6865 5.99996 2.6665ZM11.3333 14.6665C12.2173 14.6665 13.0652 14.3153 13.6903 13.6902C14.3154 13.0651 14.6666 12.2172 14.6666 11.3332C14.6666 10.4491 14.3154 9.60127 13.6903 8.97615C13.0652 8.35103 12.2173 7.99984 11.3333 7.99984C10.4492 7.99984 9.60139 8.35103 8.97627 8.97615C8.35115 9.60127 7.99996 10.4491 7.99996 11.3332C7.99996 12.2172 8.35115 13.0651 8.97627 13.6902C9.60139 14.3153 10.4492 14.6665 11.3333 14.6665ZM10.6666 9.33317H11.6666V11.2132L13.2933 12.1532L12.7933 13.0198L10.6666 11.7932V9.33317Z' />
      </SvgIcon>
    </div>
  );
}

export default CustomIcon;
