import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IBlastPageState, { BlasePageEmptyState } from './BlastPageState';
import { sendBlastMsg } from './blastpageActions';
import { QueryState } from 'common/enums';

const initialState: IBlastPageState = BlasePageEmptyState;

export const blastPageSlice = createSlice({
  name: 'blastPage',
  initialState,
  reducers: {
    updateBlastPage: (state, action: PayloadAction<IBlastPageState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sendBlastMsg.pending, (state) => {
        state.state = QueryState.AWAIT;
      })
      .addCase(sendBlastMsg.fulfilled, (state) => {
        state.state = QueryState.READY;
      })
      .addCase(sendBlastMsg.rejected, (state, action) => {
        state.state = QueryState.FAIL;
        state.error = action.error?.message || 'Something went wrong!';
      });
  },
});

export const { updateBlastPage } = blastPageSlice.actions;

export default blastPageSlice.reducer;
