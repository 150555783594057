import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Nullable } from 'utils/Nullable';
import { JobPositions } from 'common/selectoptions';

export interface IGeneral {
  agencyName: string;
  agencyType: string;
  mainAgency: Nullable<string> | undefined;
  city: string;
  county: string;
  state: string;
  zipCode: string;
  address: string;
}

export interface IPoc {
  id?: number;
  fullName: string;
  workPhone: string;
  cellPhone: string;
  email: string;
  resourceRequest: boolean;
  recruitementPerson: boolean;
}

export interface IAgencyDirector {
  fullName: string | null;
  workPhone: string | null;
  cellPhone: string | null;
  email: string | null;
}

export interface IResources {
  permittedAmbulance: number;
  shiftRotation: string[];
  annualCall: number;
  serviceType: string;
  baselineCoverage: number;
  peakCoverage: number;
  countiesServed: string[];
}

export interface IPositions {
  id?: string;
  job: string;
  type: string;
  startingPay: string | null;
  positions: number;
  vacancies: number;
}

export interface IPersonnel {
  id?: string;
  name: string;
  payLvl: string;
  certifications: string;
}

export interface IEquipment {
  id?: string;
  name: string;
  type: string;
  capabilities: string;
  noStaff: number;
}

export interface AgencyFormData {
  id?: string;
  general: IGeneral;
  poc: IPoc[] | [];
  director: IAgencyDirector;
  resources: IResources;
  position: IPositions[] | [];
  personnel: IPersonnel[] | [];
  equipment: IEquipment[] | [];
  deleted: number | 0;
}

export const JobSorting = [
  { job: 'EMT', type: 'Part Time' },
  { job: 'EMT', type: 'Full Time' },
  { job: 'AEMT', type: 'Part Time' },
  { job: 'AEMT', type: 'Full Time' },
  { job: 'Paramedic', type: 'Part Time' },
  { job: 'Paramedic', type: 'Full Time' },
  { job: 'Critical Care', type: 'Part Time' },
  { job: 'Critical Care', type: 'Full Time' },
  { job: 'Driver', type: 'Part Time' },
  { job: 'Driver', type: 'Full Time' },
  { job: 'CP/MIH', type: 'Part Time' },
  { job: 'CP/MIH', type: 'Full Time' },
];

const jobListDefault = () => {
  const types = ['Part Time', 'Full Time'];
  const position: IPositions[] = JobPositions.map((j) => {
    return types.map((type) => ({
      job: j.label,
      type,
      startingPay: '',
      positions: 0,
      vacancies: 0,
    }));
  }).flat();

  return position;
};

export const defaultValues = {
  general: {
    agencyName: '',
    agencyType: '',
    mainAgency: undefined,
    city: '',
    county: '',
    state: '',
    zipCode: '',
    address: '',
  },
  poc: [
    {
      fullName: '',
      workPhone: '',
      cellPhone: '',
      email: '',
      resourceRequest: false,
      recruitementPerson: false,
    },
    {
      fullName: '',
      workPhone: '',
      cellPhone: '',
      email: '',
      resourceRequest: false,
      recruitementPerson: false,
    },
  ],
  director: {
    fullName: '',
    workPhone: '',
    cellPhone: '',
    email: '',
  },
  resources: {
    permittedAmbulance: 0,
    shiftRotation: ['12 hour'],
    annualCall: 0,
    serviceType: '',
    baselineCoverage: 0,
    peakCoverage: 0,
    countiesServed: [],
  },
  position: jobListDefault(),
  personnel: [],
  equipment: [],
  deleted: 0,
};

function useAgencyForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        general: yup.object().shape({
          agencyName: yup.string().required('This field is required!'),
          agencyType: yup.string().required('This field is required!'),
          mainAgency: yup.string().nullable(),
          city: yup.string().required('This field is required!'),
          county: yup.string().required('This field is required!'),
          state: yup.string().required('This field is required!'),
          zipCode: yup.string().required('This field is required!'),
          address: yup.string().required('This field is required!'),
        }),
        poc: yup.array().of(
          yup.object().shape(
            {
              fullName: yup.mixed().when(['email'], {
                is: (email: string) => email,
                then: yup.string().required('This field is required!'),
              }),
              workPhone: yup.string(),
              cellPhone: yup.string(),
              email: yup.mixed().when(['fullName'], {
                is: (fullName: string) => fullName,
                then: yup.string().email('Not a valid email').required('This field is required!'),
              }),
              resourceRequest: yup.boolean(),
              recruitementPerson: yup.boolean(),
            },
            [['email', 'fullName']],
          ),
        ),
        director: yup.mixed().when('general.agencyType', {
          is: (aType: string) => aType === 'main',
          then: yup.object().shape({
            fullName: yup.string().required('This field is required!'),
            workPhone: yup.string(),
            cellPhone: yup.string().required('This field is required!'),
            email: yup.string().email('Not a valid email!').required('This field is required!'),
          }),
        }),
        resources: yup.object().shape({
          permittedAmbulance: yup.number().required('This field is required!'),
          shiftRotation: yup.array().min(1, 'Select at least one shift'),
          annualCall: yup.number().required('This field is required!'),
          serviceType: yup.string().required('This field is required!'),
          baselineCoverage: yup.number().required('This field is required!'),
          peakCoverage: yup.number().required('This field is required!'),
          countiesServed: yup.array().min(1, 'Select at least one county'),
        }),
        position: yup
          .array()
          .of(
            yup.object().shape({
              job: yup.string().required('This field is required!'),
              type: yup.string().required('This field is required!'),
              startingPay: yup.string().nullable(),
              positions: yup.number().required('This field is required!'),
              vacancies: yup.number().required('This field is required!'),
            }),
          )
          .min(1),
        personnel: yup.array().of(
          yup.object().shape({
            name: yup.string().required('This field is required!'),
            payLvl: yup.string().required('This field is required!'),
            certifications: yup.string().required('This field is required!'),
          }),
        ),
        equipment: yup.array().of(
          yup.object().shape({
            name: yup.string().required('This field is required!'),
            type: yup.string().required('This field is required!'),
            capabilities: yup.string().required('This field is required!'),
            noStaff: yup.number().required('This field is required!'),
          }),
        ),
      }),
    [],
  );

  return useForm<AgencyFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useAgencyForm;
