import React from 'react';
import { Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { ResourceType } from 'interfaces/ResourceType';
import PersonnelHeader from './PersonnelHeader';
import { IIncidentPersonnel } from 'interfaces/IncidentRequests';

const ProvidedResourcePersonnel: React.FC<
  {
    name: ResourceType;
    active: boolean;
    personnel: IIncidentPersonnel[];
  } & BoxProps
> = ({ active = false, name, personnel }) => {
  return (
    <Stack
      id={`provided-tab-panel-${name}`}
      aria-labelledby={`provided-tab-${name}`}
      hidden={!active}
      role='tab-panel'>
      <Box>
        {active && personnel && personnel.length ? (
          personnel.map((p, idx: number) => (
            <PersonnelHeader key={idx} name={name} index={idx} personnel={p} />
          ))
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  );
};

export default ProvidedResourcePersonnel;
