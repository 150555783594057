import React from 'react';
import { Divider } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Typo from '../Typo';
import useStyles from './TitleText.styles';

const TitleText = ({
  opposite = false,
  title,
  ...props
}: { opposite?: boolean; title: string } & BoxProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} sx={{ my: { xs: 1, md: 2 }, alignItems: 'center' }}>
      <Typo className={classes.titleText} variant='h3'>
        {title}
      </Typo>
      {!opposite && props.children}
      <Divider />
      {opposite && props.children}
    </Box>
  );
};

export default TitleText;
