import React from 'react';
import { Box, Stack, Grid } from '@mui/material';
import { Typo } from 'components/primitives';
import HeaderTitlePersonnel from './HeaderTitlePersonnel';
import { ResourceType } from 'interfaces/ResourceType';
import useStyles from './ProvidedResources.styles';
import { IIncidentPersonnel } from 'interfaces/IncidentRequests';
import { ISelectProp } from 'interfaces/SelectProp';
import { JobPositions } from 'common/selectoptions';

const selectLabel = (
  value: string | undefined,
  SelectOptions: ISelectProp[],
): string | undefined => {
  if (value) {
    const findValue = SelectOptions.find((m: ISelectProp) => m.value === value);
    return findValue?.label;
  } else {
    return undefined;
  }
};

const PersonnelHeader: React.FC<{
  name: ResourceType;
  index: number;
  personnel: IIncidentPersonnel;
}> = ({ index, personnel }) => {
  const classes = useStyles();
  const ttlRequested = personnel.number || 0;
  const accepted =
    (personnel && personnel?.items && personnel?.items.filter((p) => p.accepted)) || [];
  const ttlAccepted = accepted.length || 0;

  return (
    <React.Fragment key={index}>
      <Box className={classes.contentStackView}>
        {/*  Headers  */}
        <Stack direction='row' pb={1} rowGap={1} className={classes.headerGroup}>
          <Typo variant='h6' fontWeight={700}>
            {selectLabel(personnel.type, JobPositions)}
          </Typo>
          <Box className={classes.rightColGroup}>
            <Box className={classes.wrapCenter}>
              <Typo variant='h6' className={classes.title}>
                Requested
              </Typo>
              <Typo variant='h5' className={classes.requestCount}>
                {ttlRequested}
              </Typo>
            </Box>
            <Box className={classes.wrapCenter}>
              <Typo variant='h6' className={classes.title}>
                Accepted:
              </Typo>
              <Typo variant='h5' className={classes.acceptCount}>
                {ttlAccepted}
              </Typo>
            </Box>
          </Box>
        </Stack>
        {/* Title Column Headers */}
        <HeaderTitlePersonnel />
        {/*  TextField Details */}
        {personnel &&
          personnel.items &&
          accepted.map((p, idx: number) => {
            return (
              <React.Fragment key={p.id || idx}>
                <Grid container spacing={6} py={1}>
                  <Grid item xs={8}>
                    <Typo variant='body1' color={'black'}>
                      {p.name}
                    </Typo>
                  </Grid>
                  <Grid item xs={2}>
                    <Typo variant='body1' fontWeight={700} color={'black'}>
                      {p.idNumber}
                    </Typo>
                  </Grid>
                  <Grid item xs={2}>
                    <Typo variant='body1' fontWeight={700} color={'black'}>
                      {p.rateOfPay}
                    </Typo>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
      </Box>
    </React.Fragment>
  );
};

export default PersonnelHeader;
