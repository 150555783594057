import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CustomIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox='0 0 16 16' sx={{ width: 20, height: 20 }} {...props}>
      <path d='M8.6665 5.99967H12.3332L8.6665 2.33301V5.99967ZM3.99984 1.33301H9.33317L13.3332 5.33301V13.333C13.3332 13.6866 13.1927 14.0258 12.9426 14.2758C12.6926 14.5259 12.3535 14.6663 11.9998 14.6663H3.99984C3.64622 14.6663 3.30708 14.5259 3.05703 14.2758C2.80698 14.0258 2.6665 13.6866 2.6665 13.333V2.66634C2.6665 1.92634 3.25984 1.33301 3.99984 1.33301ZM4.6665 13.333H5.99984V9.33301H4.6665V13.333ZM7.33317 13.333H8.6665V7.99967H7.33317V13.333ZM9.99984 13.333H11.3332V10.6663H9.99984V13.333Z' />
    </SvgIcon>
  );
}

export default CustomIcon;
