import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
// eslint-disable-next-line
const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '10px',
      height: '40px',
      '& hr': {
        flex: 1,
      },
    },
    titleText: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  };
});

export default useStyles;
