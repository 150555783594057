import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import incidentsReducer from 'store/entities/incidents/incidentsSlice';
import blastPageReducer from 'store/entities/pages/BlastPage/blastPageSlice';
import responsePageReducer from 'store/entities/pages/ResponsePage/responsePageSlice';
import usersReducer from 'store/entities/users/usersSlice';
import agenciesReducer from 'store/entities/agencies/agenciesSlice';
import archivedReducer from 'store/entities/archived/archivedSlice';
import institutionReducer from 'store/entities/institution/institutionSlice';
import dashboardReducer from 'store/entities/dashboard/dashboardSlice';
import tokenReducer from 'store/entities/userAuth/tokenSlice';
import consultReducer from 'store/entities/consultation/consultationSlice';
import incidentDetailReducer from 'store/entities/incidentdetail/incidentdetailSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['token'],
};

const rootReducer = combineReducers({
  token: tokenReducer,
  incidents: incidentsReducer,
  blastPage: blastPageReducer,
  responsePage: responsePageReducer,
  users: usersReducer,
  agencies: agenciesReducer,
  archived: archivedReducer,
  institution: institutionReducer,
  dashboard: dashboardReducer,
  consultation: consultReducer,
  incident: incidentDetailReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
