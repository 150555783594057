import { Suspense, lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

// Components
import { PreLoaderSnippet } from 'components/snippets';
import AuthPage from 'auth';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import DashboardMain from 'pages/Dashboard/DashboardMain';
const MainHeaderPage = lazy(() => import('pages/MainPage'));
const IncidentRequest = lazy(() => import('pages/Dashboard/IncidentRequest'));
const UserManage = lazy(() => import('pages/Dashboard/UserManage'));
const UserDetails = lazy(() => import('pages/Dashboard/UserDetails'));
const EMSAgencies = lazy(() => import('pages/Dashboard/Agencies'));
const BlastEmail = lazy(() => import('pages/Dashboard/BlastEmail'));
const AgencyDetails = lazy(() => import('pages/Dashboard/AgencyDetails'));
const AgencyEdit = lazy(() => import('pages/AgencyEdit'));
const EMSArchive = lazy(() => import('pages/Dashboard/EMSArchive'));
const EducationManage = lazy(() => import('pages/Dashboard/EducationManage'));
const IncidentDetails = lazy(() => import('pages/Dashboard/IncidentDetails'));
const EMSResponse = lazy(() => import('pages/EMSResponse'));
const PublicPage = lazy(() => import('pages/PublicPage'));
const EMSADetails = lazy(() => import('pages/Dashboard/InstitutionDetails'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const CreatePassword = lazy(() => import('pages/CreatePassword'));

const JSXRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<AuthPage />}>
      {/* <Route index element={<AuthPage />} /> */}
      <Route
        path='dashboard'
        element={
          <Suspense
            fallback={
              <PreLoaderSnippet
                visible={true}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px',
                }}
              />
            }>
            <MainHeaderPage />
          </Suspense>
        }>
        <Route index element={<DashboardMain />} />
        <Route path='incident'>
          <Route path='new' element={<IncidentRequest />} />
          <Route path='edit/:id' element={<IncidentRequest />} />
          <Route path=':id' element={<IncidentDetails />} />
        </Route>
        <Route path='users'>
          <Route index element={<UserManage />} />
          <Route path={':id'} element={<UserDetails />} />
        </Route>
        <Route path='agencies'>
          <Route index element={<EMSAgencies />} />
          <Route path='sendemail' element={<BlastEmail />} />
          <Route path='new' element={<AgencyDetails />} />
          <Route path='edit/:id' element={<AgencyDetails />} />
        </Route>
        <Route path='archive'>
          <Route index element={<EMSArchive />} />
        </Route>
        <Route path='emsa'>
          <Route index element={<EducationManage />} />
          <Route path={'new'} element={<EMSADetails />} />
          <Route path={'edit/:id'} element={<EMSADetails />} />
        </Route>
      </Route>
      <Route
        path='response'
        element={
          <Suspense
            fallback={
              <PreLoaderSnippet
                visible={true}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px',
                }}
              />
            }>
            <EMSResponse />
          </Suspense>
        }
      />
      <Route
        path='consultation'
        element={
          <Suspense
            fallback={
              <PreLoaderSnippet
                visible={true}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px',
                }}
              />
            }>
            <PublicPage />
          </Suspense>
        }>
        <Route
          path='view'
          element={
            <Suspense
              fallback={
                <PreLoaderSnippet
                  visible={true}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '500px',
                  }}
                />
              }>
              <PublicPage />
            </Suspense>
          }
        />
      </Route>
      <Route
        path='agency_edit'
        element={
          <Suspense
            fallback={
              <PreLoaderSnippet
                visible={true}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px',
                }}
              />
            }>
            <AgencyEdit />
          </Suspense>
        }
      />
      <Route path='login' element={<Login />} />
      <Route
        path='reset-password'
        element={
          <Suspense
            fallback={
              <PreLoaderSnippet
                visible={true}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px',
                }}
              />
            }>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path='create-password'
        element={
          <Suspense
            fallback={
              <PreLoaderSnippet
                visible={true}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '500px',
                }}
              />
            }>
            <CreatePassword />
          </Suspense>
        }
      />
      <Route path='*' element={<NotFound />} />
    </Route>,
  ),
);

export default JSXRoutes;
