import React, { useState, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { flatMap, some } from 'lodash';
import { withStyles } from '@mui/styles';
import {
  Box,
  Stack,
  Divider,
  Tabs as MuiTabs,
  Tab as MuiTab,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  IconButton,
} from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ResponseIdFormat } from 'common/utils';
import { Button, Typo } from 'components/primitives';
import { ResourceTabSnippet } from 'components/snippets';
import { AcceptResourcesProps } from './AcceptResources.props';
import useStyles, { TabsStyles, TabItemStyles } from './AcceptResources.styles';
import ResourceTabPanel from '../ResourceTabPanel';
import { IIncidentAmbulance, IIncidentPersonnel } from 'interfaces/IncidentRequests';
import ResponseStatus from 'common/enums/ResponseStatus';
import { ResourceType, IResourceTab } from 'interfaces/ResourceType';
import { useAppSelector } from 'store/hooks';
import { selectProcessing } from 'store/entities/dashboard/dashboard.selector';

const Tabs = withStyles(TabsStyles)(MuiTabs);
const TabItem = withStyles(TabItemStyles)(MuiTab);

const hasResourceAccepted = (arr: IIncidentAmbulance[] | IIncidentPersonnel[]) => {
  return some(flatMap(arr.map((r) => r.items)), {
    accepted: true,
  });
};

const AcceptResourcesView: React.FC<AcceptResourcesProps> = ({
  incidentResponse,
  form,
  tabIndex,
  ...props
}: AcceptResourcesProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isProcessing = useAppSelector(selectProcessing);
  const { getValues } = form;
  const values = getValues();
  const [openPContent, setOpenPContent] = useState<boolean>(false);
  const [openAContent, setOpenAContent] = useState<boolean>(false);
  const agencyCounty = incidentResponse?.agency.county;
  const agencyState = incidentResponse?.agency.state || 'SC';
  const countyAddress = `${agencyCounty}, ${agencyState}`;
  const responseId = incidentResponse?.id || '';
  const details = values.incidents;
  const ambulance = details?.ambulance || [];
  const personnel = details?.personnel || [];
  const [hasEquipmentT, setHasEquipmentT] = useState<boolean>(false);
  const hasAmbulance = values.incidents?.ambulance
    ? hasResourceAccepted(values.incidents?.ambulance)
    : false;
  const hasPersonnel = values.incidents?.personnel
    ? hasResourceAccepted(values.incidents?.personnel)
    : false;
  const isAccepted = incidentResponse?.status === ResponseStatus.ACCEPTED;
  const parentPRef = useRef<HTMLDivElement>();
  const parentARef = useRef<HTMLDivElement>();

  const resourceTabs: IResourceTab[] = [
    {
      name: 'personnel',
      resource: personnel || [],
    },
    {
      name: 'ambulance',
      resource: ambulance || [],
    },
  ];

  const onAcceptAll = (name: ResourceType, accept: boolean) => {
    let formValue = values;
    if (name === 'ambulance') {
      const defVal: IIncidentAmbulance[] = ambulance.map((r) => ({
        ...r,
        items: r?.items && r.items.map((item) => ({ ...item, accepted: accept })),
      }));

      formValue = {
        ...values,
        incidents: {
          ...formValue.incidents,
          ambulance: defVal,
        },
      };
      form.reset(formValue);
    }
    if (name === 'personnel') {
      const defVal: IIncidentPersonnel[] = personnel.map((r) => ({
        ...r,
        items: r?.items && r.items.map((item) => ({ ...item, accepted: accept })),
      }));

      formValue = {
        ...values,
        incidents: {
          ...formValue.incidents,
          personnel: defVal,
        },
      };
      form.reset(formValue);
    }
  };

  const handleCheck = () => {
    setHasEquipmentT(!hasEquipmentT);
  };

  const handleShowContent = (name: ResourceType) => {
    if (name === 'personnel') {
      setOpenPContent(!openPContent);
    }
    if (name === 'ambulance') {
      setOpenAContent(!openAContent);
    }
  };

  const ListViewContainer = () => {
    return (
      <List sx={{ width: '100%', maxWidth: 600 }} aria-labelledby='nested-list-subheader'>
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <FormProvider {...form}>
              <ResourceTabSnippet
                name='personnel'
                onCheckAll={(name, accept) => onAcceptAll(name, accept)}
              />
            </FormProvider>
          </Box>
          <IconButton onClick={() => handleShowContent('personnel')}>
            {openPContent ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </ListItem>
        <Stack
          className={classes.dropDownContainer}
          ref={parentPRef}
          style={
            openPContent ? { height: parentPRef?.current?.scrollHeight + 'px' } : { height: '0px' }
          }>
          <FormProvider {...form}>
            <Box className={classes.tabContent}>
              <ResourceTabPanel active={true} name='personnel' handleClick={handleCheck} />
            </Box>
          </FormProvider>
        </Stack>
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <FormProvider {...form}>
              <ResourceTabSnippet
                name='ambulance'
                onCheckAll={(name, accept) => onAcceptAll(name, accept)}
              />
            </FormProvider>
          </Box>
          <IconButton onClick={() => handleShowContent('ambulance')}>
            {openAContent ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </ListItem>
        <Stack
          className={classes.dropDownContainer}
          ref={parentARef}
          style={
            openAContent ? { height: parentARef?.current?.scrollHeight + 'px' } : { height: '0px' }
          }>
          <FormProvider {...form}>
            <Box className={classes.tabContent}>
              <ResourceTabPanel active={true} name='ambulance' handleClick={handleCheck} />
            </Box>
          </FormProvider>
        </Stack>
      </List>
    );
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.titleView}>
        <Typo variant='h4'>Accept resources</Typo>
        <Typo variant='body2'>Please select the needed resources</Typo>
      </Box>
      <Divider sx={{ mt: 1, mb: 2 }} />

      <Box className={classes.grayBox}>
        <Typo variant='h6'>Response #{ResponseIdFormat(responseId || '')}</Typo>
        <Box>
          <Typo className={classes.countyItem}>{countyAddress}</Typo>
        </Box>
      </Box>

      {isMobile && <ListViewContainer />}
      {/* Tab Header */}
      {!isMobile && (
        <Tabs
          value={tabIndex}
          onChange={props.handleTabChange}
          indicatorColor='secondary'
          textColor='inherit'
          variant='fullWidth'
          aria-label='resources-tab'
          sx={{ mt: 2 }}>
          {resourceTabs.map((tab) => (
            <TabItem
              id={`resources-tab-${tab.name}`}
              key={tab.name}
              aria-controls={`resources-tab-panel-${tab.name}`}
              label={
                <FormProvider {...form}>
                  <ResourceTabSnippet
                    name={tab.name}
                    onCheckAll={(name, accept) => onAcceptAll(name, accept)}
                  />
                </FormProvider>
              }
              disableTouchRipple
            />
          ))}
        </Tabs>
      )}

      {/* Tab Content */}
      {!isMobile && (
        <FormProvider {...form}>
          <Box className={classes.tabContent}>
            <ResourceTabPanel active={tabIndex === 0} name='personnel' handleClick={handleCheck} />
            <ResourceTabPanel active={tabIndex === 1} name='ambulance' handleClick={handleCheck} />
          </Box>
        </FormProvider>
      )}

      {/* Footer */}
      <Box className={classes.footer}>
        {!isAccepted ? (
          <React.Fragment>
            <Button
              onClick={props.handleAccept(false, values)}
              neutral
              fullWidth
              disabled={isProcessing}>
              Cancel
            </Button>
            <Button
              progress={isProcessing}
              disabled={!hasAmbulance && !hasPersonnel}
              onClick={props.handleAccept(true, values)}
              fullWidth>
              Accept
            </Button>
          </React.Fragment>
        ) : (
          <Button onClick={props.handleAccept(false, values)} fullWidth>
            Close
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AcceptResourcesView;
