import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    step: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      columnGap: '10px',
      padding: theme.spacing(1),
      border: '1px solid #F4F4F4',
      fontSize: theme.typography.pxToRem(12),
      '& .MuiTypography-body1': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '5px',
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.common.white,
      },
    },
    activeStep: {
      backgroundColor: theme.palette.common.white,
      '& .MuiTypography-body1': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    stepBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
      borderRadius: '5px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginRight: theme.spacing(2),
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  };
});

export default useStyles;
