import { IIncidentAmbulance, IIncidentPersonnel } from 'interfaces/IncidentRequests';

function formatDeclineResources<T extends IIncidentAmbulance | IIncidentPersonnel>(
  payload: T[],
): T[] {
  return payload.map((v) => ({
    ...v,
    items: v?.items && v.items.map((item) => ({ ...item, accepted: false })),
  }));
}
export default formatDeclineResources;
