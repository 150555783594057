import React from 'react';
import { withStyles } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material';
import { Box, Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import { Typo } from 'components/primitives';
import { AcceptTabSnippet } from 'components/snippets';
import { ProvidedResourcesProps } from './ProvidedResources.props';
import { IResourceTab } from 'interfaces/ResourceType';

import useStyles, { TabsStyles, TabItemStyles } from './ProvidedResources.styles';
import ProvidedResourcePersonnel from './ProvidedResourcePersonnel';
import ProvidedResourceAmbulance from './ProvidedResourceAmbulance';
const Tabs = withStyles(TabsStyles)(MuiTabs);
const TabItem = withStyles(TabItemStyles)(MuiTab);

const ProvidedResourcesView: React.FC<ProvidedResourcesProps> = ({
  tabIndex,
  incident,
  ...props
}) => {
  const classes = useStyles();
  const personnel = incident?.personnel || [];
  const ambulance = incident?.ambulance || [];

  const resourceTabs: IResourceTab[] = [
    {
      name: 'personnel',
      resource: personnel || [],
    },
    {
      name: 'ambulance',
      resource: ambulance || [],
    },
  ];

  return (
    <Box className={classes.content}>
      <Box className={classes.closeIconContainer}>
        <CloseRounded onClick={() => props.onClose()} />
      </Box>
      <Box className={classes.titleView}>
        <Typo variant='h4'>Provided resources</Typo>
      </Box>
      {/* Tab Header */}
      <Tabs
        value={tabIndex}
        onChange={props.handleTabChange}
        indicatorColor='secondary'
        textColor='inherit'
        variant='fullWidth'
        aria-label='f-resources-tab'
        sx={{ mt: 2 }}>
        {resourceTabs.map((tab) => (
          <TabItem
            id={`resources-tab-${tab.name}`}
            key={tab.name}
            aria-controls={`resources-tab-panel-${tab.name}`}
            label={
              <AcceptTabSnippet name={tab.name} tabIndex={tabIndex} resources={tab.resource} />
            }
          />
        ))}
      </Tabs>
      {/* Tab Content */}
      <Box className={classes.tabContent}>
        <ProvidedResourcePersonnel
          name={'personnel'}
          active={tabIndex === 0}
          personnel={personnel}
        />
        <ProvidedResourceAmbulance
          name={'ambulance'}
          active={tabIndex === 1}
          ambulance={ambulance}
        />
      </Box>
    </Box>
  );
};

export default ProvidedResourcesView;
