import DefaultPaletteOptions from 'theming/defaultPalette';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (palette: DefaultPaletteOptions): any => ({
  styleOverrides: {
    root: {
      background: palette.primary?.main,
      height: '58px',
    },
  },
});
