// noinspection ExceptionCaughtLocallyJS

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IncidentInt } from 'interfaces/incident.interface';
import { ICreateResponse } from 'interfaces/response.interface';
import {
  IResponseAmbulance,
  IResponseAmbulanceDetail,
  IResponsePersonnel,
  IResponsePersonnelDetail,
  ResponseFormData,
} from 'pages/EMSResponse/hooks/useResponseForm';
import apiFetchMagic from 'services/apiFetchMagic';
import { addResponse, changeResponse, getIncidentMagic } from 'services/incidentEndPoints';
import { RootState } from 'store';
import { ResponseStatus } from '../../../../common/enums';

export const fetchIncident = createAsyncThunk('response/fetchIncident', async (_, { getState }) => {
  try {
    const state = getState() as RootState;
    const accessToken = state.responsePage.accessToken;
    const endpoint = getIncidentMagic(accessToken);
    const response = await apiFetchMagic<IncidentInt>(endpoint).then((res) => res?.data || null);

    if (response) {
      return response;
    } else {
      throw 'Submitted credentials is expired or invalid.';
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchIncident catch:', message);
    throw Error(message);
  }
});

export const createResponse = createAsyncThunk(
  'response/create',
  async (data: ResponseFormData, { getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.responsePage.accessToken;
      const incident = state.responsePage.incident;

      const payload: ICreateResponse = {
        incidentId: incident?.id || '',
        agencyId: incident?.agencyId || '',
        ambulance: cleanUpEmptyAmbulance(data),
        personnel: cleanupEmptyPersonnel(data),
        status: ResponseStatus.OPEN,
        declineMessage: '',
      };

      const params = {
        authorize: false,
        accessToken: accessToken,
      };

      const endpoint = addResponse(payload, params);
      const response = await apiFetchMagic<{ data: string }>(endpoint)
        .then((res) => res?.data || null)
        .catch((e) => {
          throw e;
        });

      if (response && response.data === 'Success') {
        return response;
      } else {
        throw 'Something went wrong';
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('create response catch:', message);
      throw Error(message);
    }
  },
);

export const updateResponse = createAsyncThunk(
  'response/update',
  async (data: ResponseFormData, { getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.responsePage.accessToken;
      const incident = state.responsePage.incident;

      const payload: ICreateResponse = {
        incidentId: incident?.id || '',
        agencyId: incident?.agencyId || '',
        responseId: incident?.responseId,
        ambulance: cleanUpEmptyAmbulance(data),
        personnel: cleanupEmptyPersonnel(data),
        status: ResponseStatus.OPEN,
        declineMessage: '',
      };

      const params = {
        authorize: false,
        accessToken: accessToken,
      };

      const endpoint = changeResponse(payload, params);

      const response = await apiFetchMagic<{ data: string }>(endpoint)
        .then((res) => res?.data || null)
        .catch((e) => {
          throw e;
        });

      if (response && response.data) {
        return response;
      } else {
        throw 'Something went wrong';
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('update response catch:', message);
      throw Error(message);
    }
  },
);

function cleanUpEmptyAmbulance(data: ResponseFormData): IResponseAmbulance[] {
  return (
    data.ambulance?.map((a) => {
      const tempA = a;
      const tempItem: IResponseAmbulanceDetail[] = [];
      tempA.items?.forEach((item) => {
        if (item.details?.length && item.vehicle?.length) {
          tempItem.push(item);
        }
      });
      tempA.items = tempItem;
      return tempA;
    }) || []
  );
}

function cleanupEmptyPersonnel(data: ResponseFormData): IResponsePersonnel[] {
  return (
    data.personnel?.map((p) => {
      const tempP = p;
      const tempItem: IResponsePersonnelDetail[] = [];
      tempP.items?.forEach((item) => {
        if (item.idNumber?.length && item.name?.length && item.rateOfPay?.length) {
          tempItem.push(item);
        }
      });
      tempP.items = tempItem;
      return tempP;
    }) || []
  );
}
