import React from 'react';
import PersonnelContainer from './PersonnelContainer';
import AmbulanceContainer from './AmbulanceContainer';
import { ResourceType } from 'interfaces/ResourceType';
import { IIncidentAmbulance, IIncidentPersonnel } from 'interfaces/IncidentRequests';
import {
  IResponseAmbulanceDetail,
  IResponsePersonnelDetail,
} from 'pages/EMSResponse/hooks/useResponseForm';

const AcceptTabSnippetView: React.FC<{
  name: ResourceType;
  tabIndex: number;
  resources: IIncidentAmbulance[] | IIncidentPersonnel[] | [];
}> = ({ tabIndex, name, resources }) => {
  let totalRequest = 0;
  let totalAccepted = 0;

  if (Array.isArray(resources) && resources.length) {
    let value = 0;
    for (const iterator of resources) {
      const val = Number(iterator.number);
      value = value + val;
    }
    totalRequest = value;
    const tempAccept: IResponseAmbulanceDetail[] | IResponsePersonnelDetail[] = [];
    resources.flatMap((v) => {
      if (v.items) {
        v.items.map((i) => {
          if (i.accepted) tempAccept.push(i);
        });
      }
    });
    totalAccepted = tempAccept.length;
  }

  return (
    <React.Fragment>
      {name && name === 'personnel' ? (
        <PersonnelContainer
          tabIndex={tabIndex}
          name={name}
          totalRequest={totalRequest}
          totalApprove={totalAccepted}
        />
      ) : (
        <AmbulanceContainer
          tabIndex={tabIndex}
          name={name}
          totalRequest={totalRequest}
          totalApprove={totalAccepted}
        />
      )}
    </React.Fragment>
  );
};

export default AcceptTabSnippetView;
