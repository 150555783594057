// noinspection ExceptionCaughtLocallyJS

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiIncidentsFilters, getIncidents } from 'services/incidentEndPoints';
import {
  IIncidentsPayloadState,
  IncidentData,
  IncidentsInterface,
} from 'interfaces/incident.interface';
import apiFetch from 'services/apiFetch';

export const fetchArchived = createAsyncThunk(
  'archived/fetchArchived',
  async (filters: ApiIncidentsFilters) => {
    try {
      const endpoint = getIncidents(filters);
      const response = await apiFetch<IncidentsInterface>(endpoint).then((res) => res.data || null);

      if (response) {
        const incidentsData: IncidentData[] = response.data.map((item) => {
          return {
            id: item.id,
            deleted: item.deleted,
            archived: item.archived,
            requestId: item.requestId,
            missionType: item.missionType,
            date: item.date,
            departmentName: item.departmentName,
            contactPerson: item.contactPerson,
            contactPhone: item.contactPhone,
            stagingLocation: item.stagingLocation,
            destination: item.destination,
            notes: item.notes,
            ambulance: item.ambulance,
            personnel: item.personnel,
            counties: item.counties,
            serviceType: item.serviceType,
            emailBody: item.emailBody,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            responses: item.responses,
          };
        });

        const page = response.page;
        const lastPage = response.last_page;
        const pagesTotal = response.total;

        const payload: IIncidentsPayloadState = {
          incidents: incidentsData,
          page: page,
          lastPage: lastPage,
          pagesTotal: pagesTotal,
        };

        return payload;
      } else {
        throw 'No data found';
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('fetchArchive catch:', message);
      throw Error(message);
    }
  },
);
