import axios, { AxiosRequestConfig } from 'axios';
import merge from 'deepmerge';
import apiConfig from './apiConfig';
import AuthProtect from 'common/enums/AuthProtect';
import { store } from 'store';
import { IEndpoint } from 'interfaces/IEndPoint';

const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

function apiFetch<T>(api: IEndpoint): Promise<{ status: number; data: T }> {
  const headers: AxiosRequestConfig = {};
  const params: any = api.params || {}; // eslint-disable-line
  const baseURL = apiConfig.baseURL;

  // Notes: Create API Token to params
  if (api.authorization === AuthProtect.REQUIRED) {
    const token = store.getState().token.authorization;
    if (token) {
      params.access_token = token; // eslint-disable-line
    }
  }
  const apiConfigMerge: AxiosRequestConfig = merge.all([
    apiConfig,
    api,
    {
      baseURL,
      headers,
      params,
    },
  ]);

  const axiosCreate = axios.create(apiConfigMerge);

  axiosCreate.interceptors.response.use(
    function (response) {
      console.log('response.status >>>', response.status);
      // if (response.status === 401 || response.status === 403) {
      //   localStorage.clear();
      //   window.location.href = '/login';
      // }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return axiosCreate({
    ...apiConfigMerge,
    validateStatus: (status: number) => {
      if (status === 401 || status === 403) {
        localStorage.clear();
        window.location.href = '/login';
      }
      return status < 500;
    },
  })
    .catch((e) => {
      console.log('apiFetch catch:', e);
      if (e instanceof Error) {
        if (e.message === 'Network Error') {
          localStorage.clear();
          window.location.href = '/login';
        }
      }
      throw {
        message: genericErrorMessage,
      };
    })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        localStorage.clear();
        window.location.href = '/login';
      }

      return {
        status: response.status,
        data: response.data,
      };
    });
}

export default apiFetch;
