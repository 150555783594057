import { QueryState } from 'common/enums';
import { Nullable } from 'utils/Nullable';
import { AgencyFormData } from 'pages/Dashboard/AgencyDetails/hooks/useAgencyForm';
import { ISelectProp } from 'interfaces/SelectProp';

export interface IAgenciesState {
  state: QueryState;
  agencies: Nullable<AgencyFormData[]>;
  agency: Nullable<AgencyFormData> | undefined;
  mainAgencyOptions: ISelectProp[] | [];
  agencyNameOptions: ISelectProp[] | [];
  page: number;
  pageLimit: number;
  initialPageTotal: number;
  pageTotal: number;
  error: Nullable<string>;
  accessToken: string | undefined;
  isEmailDuplicate: boolean;
}

export const AgenciesEmptyState: IAgenciesState = {
  state: QueryState.UNDEFINED,
  agencies: [],
  agency: null,
  mainAgencyOptions: [],
  agencyNameOptions: [],
  page: 1,
  pageLimit: parseInt(process.env.REACT_APP_LIST_LIMIT || '10'),
  initialPageTotal: 0,
  pageTotal: 0,
  error: null,
  accessToken: undefined,
  isEmailDuplicate: false,
};
