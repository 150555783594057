// noinspection ExceptionCaughtLocallyJS

import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConsultation, getConsultation } from 'services/consultEndPoints';
import apiFetch from 'services/apiFetch';
import { PublicFormData } from 'pages/PublicPage/hooks/usePublicForm';
import { IConsultation } from 'interfaces/consultation.interface';

export const createConsultation = createAsyncThunk(
  'consultation/create',
  async (data: PublicFormData) => {
    try {
      const endpoint = addConsultation(data);
      const response = await apiFetch<string>(endpoint).then((res) => res?.data || null);

      if (response) {
        return response;
      } else {
        throw 'Something went wrong!';
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('createConsultation catch:', message);
      throw Error(message);
    }
  },
);

export const fetchConsultation = createAsyncThunk('consultation/fetch', async (id: string) => {
  try {
    const endpoint = getConsultation(id);
    const response = await apiFetch<IConsultation>(endpoint).then((res) => res?.data || null);

    if (response) {
      const payload: PublicFormData = {
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        phoneNumber: response.phoneNumber,
        certificateLvl: {
          emr: Boolean(response.emr),
          emt: Boolean(response.emt),
          aemt: Boolean(response.aemt),
          paramedic: Boolean(response.paramedic),
          ccpfp: Boolean(response.ccpfp),
          nocert: Boolean(response.nocert),
          otherCert: Boolean(response.otherCert),
        },
        counties: response.counties,
        lookingFor: {
          education: Boolean(response.education),
          employment: Boolean(response.employment),
          generalInfo: Boolean(response.generalInfo),
          rideAlong: Boolean(response.rideAlong),
          other: Boolean(response.other),
        },
        interest: {
          emergent: Boolean(response.emergent),
          nonemergent: Boolean(response.nonemergent),
          ambulanceDriver: Boolean(response.ambulanceDriver),
        },
        otherInfo: response.otherInfo,
        sourceInfo: response.sourceInfo,
        residentUS: Boolean(response.residentUS),
        residentCarolina: Boolean(response.residentCarolina),
      };
      return payload;
    } else {
      throw 'Something went wrong!';
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    console.log('fetchConsultation catch:', message);
    throw Error(message);
  }
});
