import { createTheme } from '@mui/material/styles';
import MuiAppBarStyles from './overrides/MuiAppBar';
import MuiButtonStyles from './overrides/MuiButton';
import MuiLinkStyles from './overrides/MuiLink';
import MuiOutlinedInputStyles from './overrides/MuiOutlinedInput';
import MuiPaperStyles from './overrides/MuiPaper';

import customPallet from './palettes/customPalette';
import typography from './typography';

const theme = createTheme({
  palette: customPallet,
  typography,
  components: {
    MuiAppBar: MuiAppBarStyles(customPallet),
    MuiButton: MuiButtonStyles(customPallet),
    MuiLink: MuiLinkStyles(customPallet),
    MuiOutlinedInput: MuiOutlinedInputStyles(customPallet),
    MuiPaper: MuiPaperStyles(customPallet),
  },
});

export default theme;
