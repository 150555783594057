import { QueryState } from 'common/enums';
import { IncidentData } from 'common/interfaces/incident.interface';
import { IResponsesDB } from 'common/interfaces/response.interface';
import { INotifications } from 'interfaces/notification.interface';

export interface IIncidentDetailState {
  loading: boolean;
  processing: boolean;
  serviceAction: QueryState;
  incident: IncidentData | null;
  responses: IResponsesDB[] | [];
  requests: INotifications[] | [];
  error: string | null | undefined;
}

export const IncidentDetailEmptyState: IIncidentDetailState = {
  loading: false,
  processing: false,
  serviceAction: QueryState.UNDEFINED,
  incident: null,
  responses: [],
  requests: [],
  error: null,
};
