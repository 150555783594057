import React from 'react';
import clsx from 'clsx';
import { useTheme, IconButton, CircularProgress, Box } from '@mui/material';
import { ArchiveButtonProps } from './ArchiveButton.props';
import { ArchiveIcon } from 'assets/icons';
import useStyles from './ArchiveButton.styles';

const ArchiveButton: React.FC<ArchiveButtonProps> = ({
  active,
  className,
  awaitArchive,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <IconButton className={clsx(className)} disableRipple={active} onClick={onClick}>
      {awaitArchive ? (
        <CircularProgress size={18} />
      ) : (
        <Box className={classes.iconContainer}>
          <ArchiveIcon
            fontSize='medium'
            style={{ color: active ? theme.palette.primary.dark : 'inherit' }}
          />
        </Box>
      )}
    </IconButton>
  );
};

export default ArchiveButton;
