import AuthProtect from 'common/enums/AuthProtect';
import AES from 'crypto-js/aes';
import { IEndpoint } from 'interfaces/IEndPoint';

export const authLogin: (username: string, password: string) => IEndpoint = (
  username: string,
  password: string,
) => {
  const passwordKey = process.env.REACT_APP_PASSWORD_KEY;
  if (!passwordKey) {
    throw { status: 400, message: 'Missing password encryption key' };
  }

  return {
    authorization: AuthProtect.NOT_REQUIRED,
    url: 'login',
    method: 'POST',
    data: {
      password: AES.encrypt(password, passwordKey).toString(),
      email: username,
    },
  };
};

export const reqResetPass = (email: string): IEndpoint => {
  const data = { email };
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: 'reset',
    data,
  };
};

export const updatePassword = (email: string, password: string, accessToken: string): IEndpoint => {
  const data = { email, password };
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: 'setPassword?access_token=' + accessToken,
    data,
  };
};
