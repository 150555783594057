import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IResponsePageState, { ResponsePageEmptyState } from './responsePageState';
import { updateResponse, createResponse, fetchIncident } from './responsePageThunk';
import { QueryState } from 'common/enums';

const initialState: IResponsePageState = ResponsePageEmptyState;

export const responsePageSlice = createSlice({
  name: 'responsePage',
  initialState,
  reducers: {
    updateResponsePage: (state, action: PayloadAction<IResponsePageState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateTokenPage: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.accessToken = action.payload.accessToken;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIncident.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchIncident.fulfilled, (state, action) => {
        state.loading = false;
        state.incident = action.payload;
      })
      .addCase(fetchIncident.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
        state.state = QueryState.FAIL;
      })
      .addCase(createResponse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.state = QueryState.AWAIT;
      })
      .addCase(createResponse.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.state = QueryState.CREATED;
      })
      .addCase(createResponse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
        state.state = QueryState.FAIL;
      })
      .addCase(updateResponse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.state = QueryState.AWAIT;
      })
      .addCase(updateResponse.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.state = QueryState.UPDATED;
      })
      .addCase(updateResponse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
        state.state = QueryState.FAIL;
      });
  },
});

export const { updateResponsePage, updateTokenPage } = responsePageSlice.actions;

export default responsePageSlice.reducer;
