import { QueryState, BlastFormStep } from 'common/enums';
import { StackableStep } from 'components/fragments/StackableSteps';
import { Nullable } from 'utils/Nullable';

export interface IBlastPageState {
  state: QueryState;
  steps: StackableStep[];
  error: Nullable<string>;
}

export const BlasePageEmptyState: IBlastPageState = {
  state: QueryState.UNDEFINED,
  error: null,
  steps: [
    {
      step: BlastFormStep.RECIPIENTS,
      title: 'Recipients',
      active: true,
    },
    {
      step: BlastFormStep.DETAILS,
      title: 'Details',
      active: false,
    },
  ],
};

export default IBlastPageState;
