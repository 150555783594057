import { Theme } from '@mui/material';
// eslint-disable-next-line
const TextStyles = (theme: Theme): any => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: '#ffff',
    },
    '& .MuiInputBase-multiline': {
      backgroundColor: '#ffff',
    },
  },
});

export { TextStyles };
