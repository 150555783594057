import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxHeight: theme.spacing(25),
      minHeight: theme.spacing(15),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    countiesWrapper: {
      display: 'flex',
      textAlign: 'left',
    },
  };
});

export default useStyles;
