import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { userLogin } from 'store/entities/userAuth/tokenActions';
import { UserLogin } from './hooks/useLoginForm';
import { Nullable } from 'common/utils';
import { LoginProps } from './Login.props';
import LoginView from './Login.view';

const LoginErrMessage = {
  MISSING: 'Please enter the username and password',
  INVALID: 'You have entered an invalid username and/or password',
  SERVER: 'Sorry, something went wrong there. Please try again.',
};

const LoginContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState<Nullable<string>>(null);
  const [inProcess, setInProcess] = useState<boolean>(false);

  const onSubmit: SubmitHandler<UserLogin> = async (data) => {
    setInProcess(true);
    const { username, password } = data;
    const response = await dispatch(userLogin({ username, password }));

    if (response && response.payload) {
      navigate('/dashboard');
      setErrMessage(null);
    } else {
      setErrMessage(LoginErrMessage.SERVER);
    }
    setInProcess(false);
  };

  const handlePasswordReset = async () => {
    navigate('/reset-password');
  };

  const combinedProps: LoginProps = {
    inProcess,
    errMessage,
    onSubmit,
    handlePasswordReset,
  };

  return <LoginView {...combinedProps} />;
};

export default LoginContainer;
