import React from 'react';
import { CircularProgress } from '@mui/material';
import MuiButton from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteButtonProps } from './DeleteButton.props';
import { ButtonStyles } from './DeleteButton.styles';
import { withStyles } from '@mui/styles';

const StyledButton = withStyles(ButtonStyles)(MuiButton);

const ProgressIcon = () => <CircularProgress color='inherit' size={22} sx={{ mr: 0.5 }} />;

const DeleteButton: React.FC<DeleteButtonProps> = ({ progress = false, ...props }) => {
  return (
    <StyledButton
      {...props}
      startIcon={!progress && props.startIcon}
      endIcon={progress ? <ProgressIcon /> : props.endIcon}
      disableElevation>
      {props.children}
    </StyledButton>
  );
};

export default DeleteButton;
