import { PublicFormData } from 'pages/PublicPage/hooks/usePublicForm';

export interface IConsultationState {
  loading: boolean;
  success: boolean;
  consultation: PublicFormData | null;
  error: string | null;
}

export const ConsultationEmptyState: IConsultationState = {
  loading: false,
  success: false,
  consultation: null,
  error: null,
};
