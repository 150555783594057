import React from 'react';
import Box from '@mui/material/Box';
import { Typo } from 'components/primitives';
import useStyles from './AcceptTabSnippet.style';
import { ResourceType } from 'interfaces/ResourceType';

const AmbulanceContainer: React.FC<{
  tabIndex: number;
  name: ResourceType;
  totalRequest: number;
  totalApprove: number;
}> = ({ tabIndex, name, totalRequest, totalApprove }) => {
  const classes = useStyles();
  const active: boolean = tabIndex === 1;
  return (
    <React.Fragment>
      <Box className={classes.tabContainer}>
        <Typo variant='h6'>{name === 'ambulance' ? 'Ambulance' : 'Personnel'}</Typo>
        <Box flexDirection='row' display='flex'>
          <Box flexDirection='row' display='flex' alignItems='center'>
            <Typo variant='caption' className={classes.title}>
              Requested:
            </Typo>
            <Typo className={active ? classes.count : classes.countDefault}>{totalRequest}</Typo>
          </Box>
          <Box flexDirection='row' display='flex' alignItems='center'>
            <Typo variant='caption' className={classes.title}>
              Accepted:
            </Typo>
            <Typo className={active ? classes.count : classes.countInActive}>{totalApprove}</Typo>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AmbulanceContainer;
