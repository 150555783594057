import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CustomIcon(props: SvgIconProps) {
  return (
    <div style={{ position: 'relative' }}>
      <SvgIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-30%)' }} {...props}>
        <path
          d='M8.33337 0.333328C6.91889 0.333328 5.56233 0.895231 4.56214 1.89543C3.56194 2.89562 3.00004 4.25217 3.00004 5.66666C3.00004 7.08115 3.56194 8.4377 4.56214 9.4379C5.56233 10.4381 6.91889 11 8.33337 11C9.74786 11 11.1044 10.4381 12.1046 9.4379C13.1048 8.4377 13.6667 7.08115 13.6667 5.66666H12.3334C12.3334 7.87999 10.5467 9.66666 8.33337 9.66666C7.27251 9.66666 6.25509 9.24523 5.50495 8.49509C4.7548 7.74494 4.33337 6.72753 4.33337 5.66666C4.33337 4.6058 4.7548 3.58838 5.50495 2.83823C6.25509 2.08809 7.27251 1.66666 8.33337 1.66666C8.62004 1.66666 8.90671 1.69999 9.18004 1.75999L10.2534 0.693328C9.64004 0.453328 9.00004 0.333328 8.33337 0.333328ZM12.7267 1.38666L8.33337 5.77999L6.74671 4.19333L5.80671 5.14L8.33337 7.66666L13.6667 2.33333L12.7267 1.38666ZM2.28671 2.88C1.67719 3.37997 1.18593 4.00869 0.848202 4.72103C0.510475 5.43337 0.334658 6.21165 0.333374 7C0.333374 8.41448 0.895277 9.77104 1.89547 10.7712C2.89567 11.7714 4.25222 12.3333 5.66671 12.3333C6.09337 12.3333 6.51337 12.28 6.92004 12.18C5.74671 11.92 4.66671 11.3333 3.78004 10.5267C3.14162 10.1851 2.60786 9.67665 2.23569 9.05557C1.86351 8.43449 1.66686 7.72405 1.66671 7C1.66671 6.79999 1.68671 6.60666 1.71337 6.40666C1.68671 6.16 1.66671 5.91333 1.66671 5.66666C1.66671 4.70666 1.88004 3.75333 2.28671 2.88Z'
          fill='currentColor'
        />
      </SvgIcon>
    </div>
  );
}

export default CustomIcon;
