import React from 'react';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import useStyles from './RequestItemBox.styles';
import { PaperLayout } from 'components/layouts';
import { Typo } from 'components/primitives';

const RequestItemBoxView: React.FC<
  {
    name: string;
    meta?: { label: string | number; value: string | number | undefined }[];
    onSelect?: (event: React.MouseEvent<HTMLElement> | undefined) => void;
  } & BoxProps
> = ({ meta = [], name, onSelect, ...props }) => {
  const classes = useStyles();
  return (
    <a aria-hidden='true' onClick={onSelect}>
      <Box {...props}>
        <PaperLayout className={clsx(classes.root)} sx={{ px: 2, py: 1, minHeight: '50px' }}>
          <Box className={classes.leftColumn}>
            <Typo variant='h5'>
              <strong>{name}</strong>
            </Typo>
            {meta?.length > 0 && (
              <Stack direction='row' columnGap={1} className={classes.meta}>
                {meta?.map((m) => (
                  <Typo key={m.label} variant='body2' color='GrayText'>
                    {m.label}
                    <strong>{m.value || ''}</strong>
                  </Typo>
                ))}
              </Stack>
            )}
          </Box>
          <Box className={classes.rightColumn}>{props.children}</Box>
        </PaperLayout>
      </Box>
    </a>
  );
};

export default RequestItemBoxView;
