import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
import { ISelectProp } from 'interfaces/SelectProp';
import { IUserInfo } from 'pages/Dashboard/UserManage/hooks/useUserForm';

export interface ApiUserManageFilters {
  keyword?: string;
  emsType?: string | ISelectProp;
  accountType?: string | ISelectProp;
  limit?: number;
  offset?: number;
  orderBy?: string;
}

function setUserParams(filters: ApiUserManageFilters): ApiUserManageFilters {
  const params: ApiUserManageFilters = {};

  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (filters.emsType) {
    params.emsType = filters.emsType;
  }

  if (filters.accountType) {
    params.accountType = filters.accountType;
  }

  if (filters.offset || filters.offset === 0) {
    params.offset = filters.offset;
  }

  if (filters.limit) {
    params.limit = filters.limit;
  }

  return params;
}

export const getUsers = (filters: ApiUserManageFilters): IEndpoint => {
  const params = setUserParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'users',
    params: params,
  };
};

export const getEMS = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'users/ems',
  };
};

export const addUser = (data: IUserInfo): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'user',
    data,
  };
};

export const updateUser = (data: IUserInfo, id: string): IEndpoint => {
  const params = { id };
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: 'user',
    params,
    data,
  };
};

export const voidUser = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: 'user/' + id,
  };
};
