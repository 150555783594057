export enum RequestFormStep {
  INCIDENT_DETAILS = 'incidentDetails',
  AMBULANCE = 'ambulance',
  PERSONNEL = 'personnel',
  REGIONS = 'regions',
  SEND_REQUEST = 'sendRequest',
}

export enum ResponseFormStep {
  AMBULANCE = 'ambulance',
  PERSONNEL = 'personnel',
  SEND_REQUEST = 'sendRequest',
}

export enum BlastFormStep {
  RECIPIENTS = 'recipients',
  DETAILS = 'details',
}
