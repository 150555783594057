import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enums/AuthProtect';
import { ISelectProp } from 'interfaces/SelectProp';
import { IAgencyDetails } from 'interfaces/agency.interface';
import { ISendBlastPayload, SendReqMsgPayload } from 'interfaces/blastmsg.interface';

export interface ApiAgencyFilters {
  keyword?: string;
  name?: string;
  county?: string | ISelectProp;
  type?: string;
  limit?: number;
  offset?: number;
  orderBy?: string;
}

function setAgencyParams(filters: ApiAgencyFilters): ApiAgencyFilters {
  const params: ApiAgencyFilters = {};
  if (filters.keyword) {
    params.keyword = filters.keyword.trim();
  }

  if (filters.name) {
    params.name = filters.name;
  }

  if (filters.county) {
    params.county = filters.county;
  }

  if (filters.type) {
    params.type = filters.type;
  }
  if (filters.offset || filters.offset === 0) {
    params.offset = filters.offset;
  }

  if (filters.limit) {
    params.limit = filters.limit;
  }

  return params;
}

export const addAgency = (data: IAgencyDetails): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'agency',
    data,
  };
};

export const updateAgency = (
  params: Partial<{ data: IAgencyDetails; authorize: boolean; accessToken: string }>,
): IEndpoint => {
  return {
    authorization: params?.authorize ? AuthProtect.REQUIRED : AuthProtect.NOT_REQUIRED,
    method: 'PUT',
    url: params?.authorize ? 'agency' : `agency?access_token=${params.accessToken}`,
    data: params.data,
  };
};

export const deleteAgency = (data: { id: string; deleted: number }): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'DELETE',
    url: 'agency',
    data,
  };
};

export const sendBlast = (data: ISendBlastPayload): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'blastemail',
    data,
  };
};

// Fetch APIs
export const getAgency = (filters: ApiAgencyFilters): IEndpoint => {
  const params = setAgencyParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'agency',
    params: params,
  };
};

export const getMainAgencies = (
  params: Partial<{ authorize: boolean; accessToken: string }>,
): IEndpoint => {
  return {
    authorization: params?.authorize ? AuthProtect.REQUIRED : AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: params?.authorize ? 'agency/main' : `agency/main?access_token=${params?.accessToken}`,
  };
};

export const getAgencyNames = (): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'agency/names',
  };
};

export const getAgencyEdit = (accessToken: string): IEndpoint => {
  const params = { access_token: accessToken }; // eslint-disable-line
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: 'agency/link',
    params,
  };
};

export const getAgencyCount = (county: string): IEndpoint => {
  const params = { county };
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: 'agency/count',
    params,
  };
};

export const getAgencyById = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `agency/${id}`,
  };
};

export const getUpdatedTokenById = (id: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `agency/new-token/${id}`,
  };
};

export const sendReqUpdateMsg = (data: SendReqMsgPayload): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: 'agency/send-msg-req',
    data,
  };
};
