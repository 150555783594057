import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

export interface UserLogin {
  username: string;
  password: string;
}

function useLoginForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        username: string().required('Username is required'),
        password: string().required('Password is required'),
      }),
    [],
  );

  return useForm<UserLogin>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useLoginForm;
