import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    menuWrapper: {
      height: '100%',
      width: '220px',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
    },
    menuList: {
      margin: '10px',
    },
    menuItem: {
      marginBottom: '5px',
      padding: '10px',
      borderRadius: '5px',
      color: '#000000',
      '& .MuiListItemIcon-root': {
        color: '#000000',
      },
      '&.MuiMenuItem-root:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& .MuiListItemIcon-root': {
          color: theme.palette.common.white,
        },
      },
    },
  };
});
// eslint-disable-next-line
const DrawerStyles = (theme: Theme): any => ({
  root: {},
  paperAnchorLeft: {
    padding: '70px 0 10px 10px',
    border: 'none',
    backgroundColor: 'transparent',
  },
});

export { DrawerStyles };
export default useStyles;
