import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box, { BoxProps } from '@mui/material/Box';
import { Typo } from 'components/primitives';

import useStyles from './RequestResource.styles';

const renderTitle = (text: string, mobile: boolean) => {
  return text?.substring(0, mobile ? 1 : text?.length);
};

const RequestResourceView: React.FC<
  {
    meta?: { label: string; value: string | number }[];
    title: string;
  } & BoxProps
> = ({ meta = [], title, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box {...props} className={clsx(classes.root, props.className)}>
      <Typo variant='h6' className={classes.title}>
        {title}
      </Typo>
      <Box className={classes.meta}>
        {meta.map((m) => (
          <React.Fragment key={m.label}>
            <Typo variant='body2' color='textSecondary'>
              {renderTitle(m.label, isMobile)}:
            </Typo>
            <Typo variant='body1' fontWeight={600}>
              {m.value}
            </Typo>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default RequestResourceView;
