import React from 'react';
import { IResponsesDB } from 'interfaces/response.interface';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { PaperLayout } from 'components/layouts';
import { Button, Typo } from 'components/primitives';
import useStyles from './ResponseCard.styles';
import { ResponseIdFormat } from 'common/utils';
import { ResponseStatus } from 'common/enums';

const ResponseCardView: React.FC<
  {
    incidentResponse: IResponsesDB;
    onAccept: (accept: boolean, reqPayload: IResponsesDB) => void;
  } & BoxProps
> = ({ incidentResponse, onAccept, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const disableBtn = incidentResponse.status !== ResponseStatus.OPEN;
  const personnel = incidentResponse.incidents.personnel || [];
  const ambulance = incidentResponse.incidents.ambulance || [];
  const countPersonnel = personnel.flatMap((p) => p.items).length || 0;
  const countAmbulance = ambulance.flatMap((a) => a.items).length || 0;

  const handleAction = (accept: boolean) => {
    if (onAccept) onAccept(accept, incidentResponse);
  };
  return (
    <Box className={classes.root} {...props}>
      <PaperLayout elevation={3} sx={{ m: 0, p: 2 }}>
        <Box>
          <Typo mb={1} variant='body2' color='textSecondary'>
            Resource request #{incidentResponse?.incidents?.requestId || 0}
          </Typo>
          <Typo variant='h5' fontWeight={600}>
            {`Response #${ResponseIdFormat(incidentResponse?.id || '')}`}
          </Typo>
        </Box>
        <Box className={classes.flexBoxes} sx={{ mb: 2 }}>
          <Box className={classes.greyBox}>
            <Typo variant='body1'>Personnel</Typo>
            <Typo variant='h6' fontWeight={600}>
              {countPersonnel}
            </Typo>
          </Box>
          <Box className={classes.greyBox}>
            <Typo variant='body1'>Ambulance</Typo>
            <Typo variant='h6' fontWeight={600}>
              {countAmbulance}
            </Typo>
          </Box>
        </Box>
        <Box className={classes.flexBoxes}>
          {isMobile ? (
            <React.Fragment>
              <Button neutral fullWidth disabled={disableBtn} onClick={() => handleAction(false)}>
                <HighlightOffIcon fontSize='medium' />
              </Button>
              <Button fullWidth disabled={disableBtn} onClick={() => handleAction(true)}>
                <CheckCircleOutlineIcon fontSize='medium' />
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button neutral fullWidth disabled={disableBtn} onClick={() => handleAction(false)}>
                Decline
              </Button>
              <Button fullWidth disabled={disableBtn} onClick={() => handleAction(true)}>
                Accept
              </Button>
            </React.Fragment>
          )}
        </Box>
      </PaperLayout>
    </Box>
  );
};

export default ResponseCardView;
