import React from 'react';
import { OnChangeValue } from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { Typo, FormGroup, TextField, SingleSelect } from 'components/primitives';
import { Box, Grid, Divider, useTheme } from '@mui/material';
import { UserTypes } from 'pages/Dashboard/UserManage/UserManage.props';
import { ISelectProp } from 'interfaces/SelectProp';
import PhoneNumberFormat from 'common/utils/PhoneNumberFormat';
import useStyles from './UserAddFormModalView.styels';

const UserAddFormModalView: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { control } = useFormContext();

  const handleSelectChange =
    (onChange: (value: string | undefined | null) => void) =>
    (newValue: OnChangeValue<ISelectProp, false>) => {
      onChange(newValue?.value);
    };

  const selectLabel = (value: string | undefined): ISelectProp | undefined => {
    if (value) {
      return UserTypes.find((m: ISelectProp) => m.value === value);
    } else {
      return undefined;
    }
  };

  const handlePhoneChange =
    (onChange: (value: string | undefined | null) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(PhoneNumberFormat(event.target.value));
    };

  return (
    <Box className={classes.container}>
      <Typo variant='h5' fontWeight={600} sx={{ pb: theme.spacing(2) }}>
        Add new user
      </Typo>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Controller
            name={'email'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Email' error={error}>
                <TextField
                  error={Boolean(error)}
                  placeholder='mail@gmail.com'
                  value={value}
                  onChange={onChange}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Controller
            name={'agency'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Home agency' error={error}>
                <TextField
                  error={Boolean(error)}
                  placeholder='Agency name'
                  value={value}
                  onChange={onChange}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'name'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Name' error={error}>
                <TextField
                  error={Boolean(error)}
                  placeholder='John Doe'
                  value={value}
                  onChange={onChange}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'account'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Account type'>
                <SingleSelect
                  error={Boolean(error)}
                  options={UserTypes}
                  value={selectLabel(value)}
                  onChange={handleSelectChange(onChange)}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'workNumber'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Work phone number' error={error}>
                <TextField
                  error={Boolean(error)}
                  placeholder='123-123-1234'
                  value={PhoneNumberFormat(value)}
                  onChange={handlePhoneChange(onChange)}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'mobileNumber'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Cellphone number' error={error}>
                <TextField
                  error={Boolean(error)}
                  placeholder='123-123-1234'
                  value={value}
                  onChange={handlePhoneChange(onChange)}
                />
              </FormGroup>
            )}
          />
        </Grid>
      </Grid>
      <Typo variant='h6' fontWeight={600} sx={{ pb: theme.spacing(1), pt: theme.spacing(2) }}>
        Password
      </Typo>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'password'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Password' error={error}>
                <TextField
                  error={Boolean(error)}
                  type='password'
                  value={value}
                  onChange={onChange}
                />
              </FormGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Controller
            name={'confirmPassword'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormGroup label='Confirm Password' error={error}>
                <TextField
                  error={Boolean(error)}
                  type='password'
                  value={value}
                  onChange={onChange}
                />
              </FormGroup>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserAddFormModalView;
