import { ISelectProp } from 'interfaces/SelectProp';

export const AmbulanceOptions: ISelectProp[] = [
  {
    label: 'Quick Response Vehicle (ALS)',
    value: 'QRV',
  },
  {
    label: 'Basic Life Support Ambulance',
    value: 'BLSA',
  },
  {
    label: 'Advanced Life Support Ambulance',
    value: 'ALSA',
  },
  {
    label: 'Ventilator Equipped Ambulance',
    value: 'VEA',
  },
  {
    label: 'Special Purpose Ambulance',
    value: 'SPA',
  },
  {
    label: 'Mass Casualty / Mass Transport Unit',
    value: 'MCMTU',
  },
];

export const JobPositions: ISelectProp[] = [
  {
    label: 'EMT',
    value: 'EMT',
  },
  {
    label: 'AEMT',
    value: 'AEMT',
  },
  {
    label: 'Paramedic',
    value: 'PARAMEDIC',
  },
  {
    label: 'Critical Care',
    value: 'CRITICAL',
  },
  {
    label: 'Driver',
    value: 'DRIVER',
  },
  {
    label: 'CP/MIH',
    value: 'CP/MIH',
  },
];

export const ServiceTypes: ISelectProp[] = [
  {
    label: 'Medical Transport (Convalescent)',
    value: 'medical',
  },
  {
    label: '911 Response (Scene) With Transport Capability',
    value: '911_transport',
  },
  {
    label: '911 Response (Scene) Without Transport Capability',
    value: '911_notransport',
  },
  {
    label: 'Air Medical',
    value: 'air',
  },
  {
    label: 'Specialty Care Transport',
    value: 'specialty',
  },
];
