/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    content: {
      position: 'relative',
      width: '800px',
      height: '700px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      overflowY: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    footerBtn: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '10px',
      paddingBottom: '10px',
      width: '800px',
      position: 'fixed',
      bottom: 0,
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    closeIconContainer: {
      position: 'absolute',
      right: 2,
      top: 0,
      cursor: 'pointer',
    },
    tabContent: {
      marginTop: '0.5em',
      marginBottom: '1em',
      minHeight: '320px',
      height: '100%',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(3),
      columnGap: '10px',
    },
    titleView: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& p:last-child': {
        color: theme.palette.grey[800],
      },
    },
    grayBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      '& .MuiBox-root': {
        display: 'flex',
        justifyContent: 'flex-end',
        color: theme.palette.grey[800],
      },
    },
    stackView: {
      backgroundColor: theme.palette.grey[50],
    },
    contentStackView: {
      backgroundColor: theme.palette.grey[50],
      border: '1px solid gray',
      marginTop: '6px',
      borderRadius: '5px',
      padding: '12px',
    },
    sectionItem: {
      '& .MuiBox-root': {
        backgroundColor: theme.palette.grey[100],
        fontSize: '11px',
        fontWeight: 600,
        color: theme.palette.grey[700],
      },
    },
    headerGroup: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
    },
    rightColGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    wrapCenter: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(1),
    },
    title: {
      color: theme.palette.grey[700],
      padding: '0 12px',
    },
    requestCount: {
      color: theme.palette.common.black,
    },
    acceptCount: {
      color: theme.palette.grey[700],
    },
  };
});

const TabsStyles = (theme: Theme): any => ({
  root: {
    display: 'flex',
    alignItems: 'left',
    '& .Mui-selected': {
      borderRadius: '4px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  indicator: {
    backgroundColor: 'transparent',
    // backgroundColor: 'orange',
  },
  title: {
    color: theme.palette.grey[400],
    paddingRight: theme.spacing(1),
    '& .Mui-selected': {
      color: theme.palette.common.white,
    },
  },
});

const TabItemStyles = (theme: Theme): any => ({
  root: {
    opacity: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 0,
    paddingRight: theme.spacing(2),
    backgroundColor: 'transparent',
    textTransform: 'none',
  },
  selected: {
    backgroundColor: theme.palette.grey[100],
  },
});

export { TabsStyles, TabItemStyles };

export default useStyles;
