// noinspection ExceptionCaughtLocallyJS,JSUnusedAssignment

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUsers,
  addUser,
  updateUser,
  ApiUserManageFilters,
  getEMS,
  voidUser,
} from 'services/usersEndPoints';
import { IUserInfo } from 'pages/Dashboard/UserManage/hooks/useUserForm';
import { UsersInterfaces } from 'interfaces/users.interface';
import apiFetch from 'services/apiFetch';
import { ISelectProp } from 'interfaces/SelectProp';

export const fetchUsersList = createAsyncThunk(
  'users/fetchUsersList',
  async (filters: ApiUserManageFilters) => {
    try {
      const endpoint = getUsers(filters);
      const response: UsersInterfaces | null = await apiFetch(endpoint).then(
        (res) => res?.data || null,
      );
      if (response) {
        const pageCount = response.last_page;
        return {
          users: response.data,
          pageTotal: pageCount,
        };
      } else {
        throw Error('No data found!');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else message = String(error);
      console.log('fetchUsersList catch:', error);
      throw Error(message);
    }
  },
);

export const fetchEMSList = createAsyncThunk('users/fetchEMSList', async () => {
  try {
    const endpoint = getEMS();
    const response = await apiFetch<{ data: ISelectProp[] }>(endpoint).then(
      (res) => res?.data || [],
    );
    if (response) {
      return response;
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('fetchEMSList catch:', error);
    throw Error(message);
  }
});

export const createUser = createAsyncThunk('users/createUser', async (data: IUserInfo) => {
  try {
    const endpoint = addUser(data);
    const response = apiFetch(endpoint).then((res) => res?.data || null);
    if (response) {
      return response;
    } else {
      throw Error('Internal Server Error');
    }
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('createUser catch:', error);
    throw Error(message);
  }
});

export const updateSelectedUser = createAsyncThunk(
  'users/updateUser',
  async (payload: { data: IUserInfo; id: string }) => {
    try {
      const endpoint = updateUser(payload.data, payload.id);
      const response = await apiFetch<{ data: IUserInfo }>(endpoint).then(
        (res) => res?.data || null,
      );

      if (response) {
        return response;
      }

      // return params;
    } catch (error) {
      console.log('updateUser catch:', error);
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else message = String(error);
      throw Error(message);
    }
  },
);

export const deleteUser = createAsyncThunk('users/deleteUser', async (id: string) => {
  try {
    const endpoint = voidUser(id);
    return await apiFetch<{ message: string }>(endpoint).then((res) => res?.data || null);
  } catch (error) {
    console.log('deleteUser catch:', error);
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else message = String(error);
    throw Error(message);
  }
});
