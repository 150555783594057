import moment from 'moment';

export default function toShortDateOnly(
  dt: string | Date | undefined | null,
  format?: string,
): string {
  return dt
    ? moment(dt)
        .local()
        .format(format || 'YYYY-MM-DD')
    : '';
}
