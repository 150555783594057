import React from 'react';
import { StackableStep } from '../StackableSteps/StackableSteps.props';
import { StepBoxSnippet } from 'components/snippets';
import { MenuItem, Box, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typo } from 'components/primitives';
import useStyles from './StackableSelectSteps.styles';
import { Nullable } from 'utils/Nullable';

type StackableSelectStepsViewProps = {
  active?: Nullable<number | string>;
  steps: StackableStep[];
  onStep?: (step: number | string) => void;
};

const StackableSelectStepsView: React.FC<StackableSelectStepsViewProps> = ({
  active = null,
  steps,
  onStep,
}) => {
  const classes = useStyles();

  const RenderValue = (selected: string | number | null) => {
    let filteredValue: StackableStep = {
      step: '',
      active: false,
      complete: false,
      name: '',
      title: '',
    };
    let idx = 0;

    for (let i = 0; i < steps.length; i++) {
      const s = steps[i];
      if (s.step === selected) {
        idx = i;
        filteredValue = s;
      }
    }

    return (
      <MenuItem sx={{ display: 'flex', alignItems: 'center', ml: '-20px', mt: '-5px' }}>
        <Box className={classes.stepBox}>
          <StepBoxSnippet step={idx + 1} complete={filteredValue.complete} />
        </Box>
        <Typo variant='h4' color={'textSecondary'} fontWeight={600}>
          {filteredValue.title}
        </Typo>
      </MenuItem>
    );
  };

  const handleChange = (event: SelectChangeEvent<string | number | null>) => {
    const {
      target: { value },
    } = event;
    if (value !== null) onStep && onStep(value);
  };
  return (
    <FormControl fullWidth>
      <Select
        style={{ display: 'flex', width: '100%', height: '50px', alignItems: 'center' }}
        value={active}
        renderValue={(selected) => RenderValue(selected)}
        onChange={(event) => handleChange(event)}>
        {steps.map((s, idx) => {
          const isActive = s.active || s.step === active;
          return (
            <MenuItem
              disabled={!isActive}
              value={s.step}
              key={s.step}
              className={classes.menuWrapper}>
              <Box className={classes.stepBox}>
                <StepBoxSnippet step={idx + 1} complete={s.complete} />
              </Box>
              <Typo variant='h4' color={'textSecondary'} fontWeight={600}>
                {s.title}
              </Typo>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default StackableSelectStepsView;
