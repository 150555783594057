import { ISelectProp } from 'interfaces/SelectProp';
import React from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';

interface ISingleSelect {
  white?: boolean;
  error?: boolean;
  onChange:
    | ((newValue: SingleValue<ISelectProp>, actionMeta: ActionMeta<ISelectProp>) => void)
    | undefined;
  options: ISelectProp[];
  value: ISelectProp | undefined;
}

const SingleSelectView: React.FC<ISingleSelect> = ({
  white = false,
  error,
  options,
  onChange,
  value,
}) => {
  return (
    <Select
      styles={{
        control: (baseStyles) =>
          error
            ? { ...baseStyles, background: `${white ? '#ffff' : '#fafafa'}`, borderColor: 'red' }
            : {
                ...baseStyles,
                background: `${white ? '#ffff' : '#fafafa'}`,
              },
      }}
      options={options || []}
      onChange={onChange}
      value={value}
    />
  );
};

export default SingleSelectView;
