import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendBlast } from 'services/agencyEndPoints';
import apiFetch from 'services/apiFetch';
import { ISendBlastPayload } from 'interfaces/blastmsg.interface';

export const sendBlastMsg = createAsyncThunk(
  'agencies/sendBlastMsg',
  async (data: ISendBlastPayload) => {
    try {
      const endpoint = sendBlast(data);
      const response = await apiFetch<{ data: string }>(endpoint)
        .then((res) => res?.data?.data || null)
        .catch((e) => {
          throw Error(e);
        });

      if (response !== null) {
        return response;
      } else {
        throw Error('Sending Email Failed');
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('sendBlastMsg catch:', message);
      throw Error(message);
    }
  },
);
