// noinspection ExceptionCaughtLocallyJS,JSUnusedAssignment

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiIncidentsFilters,
  changeResponse,
  getIncidents,
  getOpenResponses,
} from 'services/incidentEndPoints';
import {
  IIncidentsPayloadState,
  IncidentData,
  IncidentsInterface,
} from 'interfaces/incident.interface';
import { ICreateResponse, IResponsesDB } from 'interfaces/response.interface';
import apiFetch from 'services/apiFetch';
import { ResponseStatus } from '../../../common/enums';

export const fetchIncidents = createAsyncThunk(
  'dashboard/fetchIncidents',
  async (filters: ApiIncidentsFilters) => {
    try {
      const endpoint = getIncidents(filters);
      const response = await apiFetch<IncidentsInterface>(endpoint).then(
        (res) => res?.data || null,
      );

      if (response) {
        const incidentsData: IncidentData[] = response.data.map((item) => {
          return {
            id: item.id,
            deleted: item.deleted,
            archived: item.archived,
            requestId: item.requestId,
            missionType: item.missionType,
            date: item.date,
            departmentName: item.departmentName,
            contactPerson: item.contactPerson,
            contactPhone: item.contactPhone,
            stagingLocation: item.stagingLocation,
            destination: item.destination,
            notes: item.notes,
            ambulance: item.ambulance,
            personnel: item.personnel,
            counties: item.counties,
            serviceType: item.serviceType,
            emailBody: item.emailBody,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            responses: item.responses,
          };
        });

        const page = response.page;
        const lastPage = response.last_page;
        const pagesTotal = response.total;

        const payload: IIncidentsPayloadState = {
          incidents: incidentsData,
          page: page,
          lastPage: lastPage,
          pagesTotal: pagesTotal,
        };

        return payload;
      } else {
        throw 'No data found';
      }
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) {
        message = error.message;
      } else message = String(error);
      console.log('fetchIncidents catch:', error);
      throw Error(message);
    }
  },
);

export const fetchResponses = createAsyncThunk('dashboard/fetchResponses', async () => {
  try {
    return await apiFetch<IResponsesDB[]>(getOpenResponses())
      .then((res) => res?.data || [])
      .catch((e) => {
        throw e;
      });
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else message = String(error);
    console.log('fetchResponses catch:', error);
    throw Error(message);
  }
});

export const updateResponse = createAsyncThunk(
  'dashboard/update-response',
  async (data: { response: IResponsesDB; status: ResponseStatus }) => {
    try {
      const payload: ICreateResponse = {
        incidentId: data.response.incidents.id,
        agencyId: data.response.agency.id || '',
        responseId: data.response.id,
        ambulance: data.response.incidents.ambulance || [],
        personnel: data.response.incidents.personnel || [],
        status: data.status,
        declineMessage: data.response.declineMessage,
      };

      const params = { authorize: true };
      const endpoint = changeResponse(payload, params);
      const resp = await apiFetch<{ data: string }>(endpoint).then((res) => res?.data || null);

      if (resp && resp.data === 'Success') return resp.data;
      else throw 'Unable to update response';
    } catch (error) {
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      console.log('update response catch:', message);
      throw Error(message);
    }
  },
);
