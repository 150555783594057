import React from 'react';
import clsx from 'clsx';
import {
  CheckOutlined as CheckOutlinedIcon,
  ChevronRight as ChevronRightIcon,
  CloseOutlined as CloseOutlinedIcon,
} from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Button, Typo } from 'components/primitives';
import { PaperLayout } from 'components/layouts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './ResponseItemBox.styles';
import { ResponseItemBoxProps } from './ResponseItemBox.props';
import { flatMap } from 'lodash';
import { ResponseStatus } from '../../../common/enums';

const ResponseItemBoxView: React.FC<ResponseItemBoxProps> = ({
  name,
  onSelect,
  paperProps,
  response,
  onResponse,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const status: ResponseStatus = response.status;
  const personnel = response.incidents.personnel || [];
  const ambulance = response.incidents.ambulance || [];
  const personnelCount = flatMap(personnel.map((p) => p.items)).length || 0;
  const ambulanceCount = flatMap(ambulance.map((a) => a.items)).length || 0;

  return (
    <a aria-hidden='true' onClick={() => onSelect}>
      <Box {...props}>
        <PaperLayout
          {...paperProps}
          sx={{ px: 2, py: 1, minHeight: '75px' }}
          className={clsx(classes.root)}>
          <Box className={classes.leftColumn}>
            <Stack direction='row' justifyContent='space-between'>
              <Box>
                <Typo variant='h5'>
                  <strong>{name}</strong>
                </Typo>
                {props.meta && props.meta?.length > 0 ? (
                  <Stack direction='row' className={classes.meta}>
                    {props.meta &&
                      props.meta.map((m) => (
                        <Typo key={m.label} variant='body2' color='textSecondary'>
                          {m.label}
                          <strong>{m.value || ''}</strong>
                        </Typo>
                      ))}
                  </Stack>
                ) : null}
              </Box>
            </Stack>
          </Box>
          <Stack direction='row' className={classes.resources}>
            <Stack direction='row' columnGap={2} sx={{ width: { xs: '100%' }, mr: { sm: 5 } }}>
              <Box className={classes.greyBox}>
                <Typo variant='body2' color='textSecondary'>
                  Personnel
                </Typo>
                <Typo variant='h5' textAlign='right'>
                  {personnelCount}
                </Typo>
                <ChevronRightIcon />
              </Box>
              <Box className={classes.greyBox}>
                <Typo variant='body2' color='textSecondary'>
                  Ambulance
                </Typo>
                <Typo variant='h5' textAlign='right'>
                  {ambulanceCount}
                </Typo>
                <ChevronRightIcon />
              </Box>
            </Stack>
            {isMobile ? (
              <Stack direction='row' columnGap={1} className={classes.responseButtons}>
                {status === ResponseStatus.OPEN && (
                  <React.Fragment>
                    <Button
                      className={classes.squareButton}
                      onClick={() => onResponse(false)}
                      neutral>
                      <CloseOutlinedIcon />
                    </Button>
                    <Button className={classes.squareButton} onClick={() => onResponse(true)}>
                      <CheckOutlinedIcon />
                    </Button>
                  </React.Fragment>
                )}
                {status === ResponseStatus.ACCEPTED && (
                  <React.Fragment>
                    <Button>Accepted</Button>
                  </React.Fragment>
                )}
                {status === ResponseStatus.REJECTED && (
                  <React.Fragment>
                    <Button neutral>Declined</Button>
                  </React.Fragment>
                )}
              </Stack>
            ) : (
              <Stack
                direction='row'
                columnGap={2}
                mr={2}
                className={
                  status === ResponseStatus.OPEN
                    ? clsx(classes.responseButtons, 'slider')
                    : clsx(classes.responseButtons, 'float-right')
                }>
                {status === ResponseStatus.OPEN && (
                  <React.Fragment>
                    <Button onClick={() => onResponse(false)} neutral>
                      Decline
                    </Button>
                    <Button onClick={() => onResponse(true)}>Accept</Button>
                  </React.Fragment>
                )}
                {status === ResponseStatus.ACCEPTED && (
                  <React.Fragment>
                    <Button>Accepted</Button>
                  </React.Fragment>
                )}
                {status === ResponseStatus.REJECTED && (
                  <React.Fragment>
                    <Button neutral>Declined</Button>
                  </React.Fragment>
                )}
              </Stack>
            )}
          </Stack>
        </PaperLayout>
      </Box>
    </a>
  );
};

export default ResponseItemBoxView;
