import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconContainer: {
      display: 'flex',
      height: '28px',
      width: 'auto',
      position: 'relative',
      alignItems: 'center',
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
  };
});

export default useStyles;
