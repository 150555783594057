import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from '../common/enums/AuthProtect';

export const getNotificationByIncident = (incidentId: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `notification/incident/${incidentId}`,
  };
};

export const sendRemindByAgency = (incidentId: string, agencyId: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `notification/${incidentId}/notify/${agencyId}`,
  };
};

export const sendRemindAll = (incidentId: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `notification/${incidentId}/notify`,
  };
};
